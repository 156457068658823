import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Link } from "react-router-dom";

function Dashboard() {
  return (
    <table
      width="100%"
      border={0}
      cellSpacing={0}
      cellPadding={0}
      style={{ backgroundColor: "#FFF" }}
    >
      <tbody>
        <tr>
          <td>
            <table
              width="100%"
              border={0}
              cellSpacing={0}
              cellPadding={0}
              routerlink="/inPlay"
              tabIndex={0}
            >
              <tbody>
                <tr>
                  <td align="center">&nbsp;</td>
                </tr>
                <tr>
                  <td align="center">
                    <a href="/in-play">
                      <img
                        src="assets/images/crick.png"
                        alt=""
                        width={112}
                        height={112}
                      />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td height={25} align="center" className="GameList">
                    <a href="/in-play" style={{ color: "#000000" }}>
                      In Play
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td>
            <table width="100%" border={0} cellSpacing={0} cellPadding={0}>
              <tbody>
                <tr>
                  <td align="center">&nbsp;</td>
                </tr>
                <tr>
                  <td align="center">
                    <a routerlink="/casino" href="/casino">
                      <img
                        src="assets/images/casino.png"
                        alt=""
                        width={112}
                        height={112}
                      />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td height={25} align="center" className="GameList">
                    <a
                      routerlink="/casino"
                      style={{ color: "#000000" }}
                      href="/casino"
                    >
                      Casino
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>
            <table width="100%" border={0} cellSpacing={0} cellPadding={0}>
              <tbody>
                <tr>
                  <td align="center">
                    <a routerlink="/complete" href="/complete-games">
                      <img
                        src="assets/images/CG1.jpg"
                        alt=""
                        width={112}
                        height={112}
                      />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td height={25} align="center" className="GameList">
                    <a
                      routerlink="/complete"
                      style={{ color: "#000000" }}
                      href="/complete-games"
                    >
                      Complete Games
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td>
            <table width="100%" border={0} cellSpacing={0} cellPadding={0}>
              <tbody>
                <tr>
                  <td align="center">
                    <a routerlink="/profile" href="/profile">
                      <img
                        src="assets/images/Profile.png"
                        alt=""
                        width={112}
                        height={112}
                      />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td height={25} align="center" className="GameList">
                    <a
                      routerlink="/profile"
                      style={{ color: "#000000" }}
                      href="/profile"
                    >
                      My Profile
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>
            <table width="100%" border={0} cellSpacing={0} cellPadding={0}>
              <tbody>
                <tr>
                  <td align="center">
                    <a routerlink="/ledger" href="/ledger">
                      <img
                        src="assets/images/CL.png"
                        alt=""
                        width={112}
                        height={112}
                      />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td height={25} align="center" className="GameList">
                    <a
                      routerlink="/ledger"
                      style={{ color: "#000000" }}
                      href="/ledger"
                    >
                      My Ledger
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td>
            <table width="100%" border={0} cellSpacing={0} cellPadding={0}>
              <tbody>
                <tr>
                  <td align="center">
                    <a routerlink="/password" href="/password">
                      <img
                        src="assets/images/CP.png"
                        alt=""
                        width={112}
                        height={112}
                      />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td height={25} align="center" className="GameList">
                    <a
                      routerlink="/password"
                      style={{ color: "#000000" }}
                      href="/password"
                    >
                      Change Password
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
        </tr>
      </tbody>
    </table>
  );
}
export default Dashboard;
