import React from "react";
import "./kingqueen.scoped.css";
export default function KingQueenJack() {
  return (
    <div className="tp-bg">
      <div id="game" data-match-id={2327197} data-provider-id={1677798} />
      {/* ******************ROULETTE MODAL CODE START**************** */}
      <div id="gameModal" className="modalHistory">
        <div className="history-modal-content">
          <span className="close-history-modal">
            <i className="fa fa-window-close" aria-hidden="true" />
          </span>
          <div className="modal-body">
            <div className="modal-container">
              <div className="btn-sec flex">
                <div className="game-btn btn flex-1-game-history c-align checked modal-section">
                  <label className="centered-button" htmlFor="">
                    <button data-button-id="game-history">GAME HISTORY</button>
                  </label>
                </div>
                <div className="rules-btn btn flex-1-game-history modal-section">
                  <label htmlFor="" className="rules-label">
                    {" "}
                    <button data-button-id="game-rules">RULES</button>{" "}
                  </label>
                </div>
              </div>
              <div className="modal-content-sec">
                <div id="matkaRules" />
                <div id="hindiMatkaRules" />
                <table
                  className="g-history-sec sec game-history-table"
                  id="gameHistoryTable"
                >
                  <thead>
                    <tr style={{}}>
                      <th>S.NO</th>
                      <th>Match ID</th>
                      <th>Winner</th>
                      <th>PLAY</th>
                      <th>WIN</th>
                    </tr>
                  </thead>
                  <tbody />
                </table>
                <div className="rules-sec sec hide ">
                  <p>
                    <span>● Welcome to KQJ Game.</span>
                  </p>
                  <p>
                    <span>● In the KQJ game one hands is dealt.</span>
                  </p>
                  <p>
                    <span>
                      ● The card dealt will be the winning hand of the game.
                    </span>
                  </p>
                  <p>
                    <span>
                      ● All the players who have placed the bet of the winning
                      hand will win the game
                    </span>
                  </p>
                  <p>
                    <span>● There will be only 12 cards in the deck.</span>
                  </p>
                  <p>
                    <span>
                      ● Payout rate for <strong>KQJ Player Market</strong> is :
                      11.0.
                    </span>
                  </p>
                  <p>
                    <span>
                      ● Games will be closed 1 hr everyday for maintenance.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ******************ROULETTE MODAL CODE END**************** */}
      <div className="tp-videp-panel">
        <div className="tp-logo tp-width flex aj-cen justify-content-start">
          <img
            alt="Kqj logo"
            src="http://sky7777.fun:80/assets/lottery/king_queen_jack/logo/kqj-logo-0cc2fb5ffaa1d36fde811bfd47c85fcb.png"
          />
          <span className="teen">KING QUEEN JACK</span>
        </div>
        <div className="tp-vid-outer tp-width">
          <iframe
            style={{ border: "none", height: "220.571px" }}
            width={700}
            height={400}
            src="https://feedvdo.jaaztech.com/Video/VideoFeed?token=QVbu1naS2M&table=T_JQK01"
          />
        </div>
        <div className="data-holder last-winners-holder">
          <div className="last-winners flex">
            <div className="flex tp-player p-left f-bs w-100">
              <p className="winner-box-title text-white">
                LAST 10 <br />
                WINNERS
              </p>
              <div className="flex flex-1 winner-box-holder justify-content-around">
                <div className="w-card-sec winner-box" data-id={2327144}>
                  <img
                    alt="Club j"
                    src="http://sky7777.fun:80/assets/lottery/king_queen_jack/last_10_wincard/Club J-6ddac69984abfe27dfdef28a9988914b.png"
                  />
                </div>
                <div className="w-card-sec winner-box" data-id={2327147}>
                  <img
                    alt="Spade j"
                    src="http://sky7777.fun:80/assets/lottery/king_queen_jack/last_10_wincard/Spade J-0b32703a0604975bdf956bef8f179618.png"
                  />
                </div>
                <div className="w-card-sec winner-box" data-id={2327153}>
                  <img
                    alt="Club k"
                    src="http://sky7777.fun:80/assets/lottery/king_queen_jack/last_10_wincard/Club K-ee8706e31ecff54d7b18ddfbb894ab72.png"
                  />
                </div>
                <div className="w-card-sec winner-box" data-id={2327159}>
                  <img
                    alt="Spade q"
                    src="http://sky7777.fun:80/assets/lottery/king_queen_jack/last_10_wincard/Spade Q-31b194c7061aeba6dbd2fad69940ef3f.png"
                  />
                </div>
                <div className="w-card-sec winner-box" data-id={2327165}>
                  <img
                    alt="Club k"
                    src="http://sky7777.fun:80/assets/lottery/king_queen_jack/last_10_wincard/Club K-ee8706e31ecff54d7b18ddfbb894ab72.png"
                  />
                </div>
                <div className="w-card-sec winner-box" data-id={2327171}>
                  <img
                    alt="Club k"
                    src="http://sky7777.fun:80/assets/lottery/king_queen_jack/last_10_wincard/Club K-ee8706e31ecff54d7b18ddfbb894ab72.png"
                  />
                </div>
                <div className="w-card-sec winner-box" data-id={2327176}>
                  <img
                    alt="Diamond j"
                    src="http://sky7777.fun:80/assets/lottery/king_queen_jack/last_10_wincard/Diamond J-145aca7d3da4b73f9f8c95c5b83f406b.png"
                  />
                </div>
                <div className="w-card-sec winner-box" data-id={2327182}>
                  <img
                    alt="Spade q"
                    src="http://sky7777.fun:80/assets/lottery/king_queen_jack/last_10_wincard/Spade Q-31b194c7061aeba6dbd2fad69940ef3f.png"
                  />
                </div>
                <div className="w-card-sec winner-box" data-id={2327187}>
                  <img
                    alt="Club q"
                    src="http://sky7777.fun:80/assets/lottery/king_queen_jack/last_10_wincard/Club Q-183a4008a52723452be92ba2b3043b61.png"
                  />
                </div>
                <div className="w-card-sec winner-box" data-id={2327191}>
                  <img
                    alt="Diamond q"
                    src="http://sky7777.fun:80/assets/lottery/king_queen_jack/last_10_wincard/Diamond Q-066e5b8e4a5e3e993a734b3dbe492508.png"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Modal */}
          <div
            className="modal fade"
            id="showDrawCardsModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="showDrawCardsModalTitle"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="showDrawCardsModalTitle">
                    KQJ Result
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="round-id">
                    <p>
                      <b>Match ID :</b>
                      <span id="heroicMatchId" />
                    </p>
                  </div>
                  <div className="player-result playingCards fourColours faceImages simpleCards inText rotateHand">
                    <div style={{ border: "none" }} className="player-a-cards">
                      <p>KQJ</p>
                      <ul className="card-a">
                        <li id="kqj-card1">
                          <a className="card" href="#">
                            <span className="rank" />
                            <span className="suit" />
                          </a>
                        </li>
                      </ul>
                      <button className="winner-btn hide player-a-winner">
                        Winner
                      </button>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <p>
                    <b>Hand ID :</b>
                    <span id="videoProviderGameID" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="data-holder d-flex justify-content-center mob-coin-section f-bs pb-4 pt-4">
          <div className="mob-coin-inner p-2">
            COINS : <span className="available_coins text-white">1407.0</span>
          </div>
        </div>
        <div className="data-holder py-0 mt-1 d-flex align-items-center bets-label-sec">
          <div className="flex tp-player p-left f-bs text-white">
            Place bets
          </div>
        </div>
        <div className="d-flex bets-outer-section my-2">
          <div className="text-center card-section-inner py-1 px-1 flex-1 bg-grey-light pb-2 br-l-5">
            <div className="f-bs text-white pb-2 d-flex align-items-center justify-content-center lh-14">
              Jacks
            </div>
            <div className="d-flex card-outer justify-content-between">
              <div
                className="card-section flex-column d-flex align-items-center kqj-bet spade_j"
                data-runner_name="Spade J"
                data-runner_id=""
                data-market_id=""
                data-odds_val=""
                data-odd_type="LAGAI"
                data-match_id=""
                data-heroic_market_type=""
              >
                <img
                  alt="Spade j"
                  src="http://sky7777.fun:80/assets/lottery/king_queen_jack/new_cards/spade_j-209b8160ea57b17bf919ca08b95db533.png"
                />
                <span className="bet-coins">0</span>
              </div>
              <div
                className="card-section flex-column d-flex align-items-center kqj-bet heart_j"
                data-runner_name="Heart J"
                data-runner_id=""
                data-market_id=""
                data-odds_val=""
                data-odd_type="LAGAI"
                data-match_id=""
                data-heroic_market_type=""
              >
                <img
                  alt="Heart j"
                  src="http://sky7777.fun:80/assets/lottery/king_queen_jack/new_cards/heart_j-a06589584539f9929d897be0a9c1e144.png"
                />
                <span className="bet-coins">0</span>
              </div>
              <div
                className="card-section flex-column d-flex align-items-center kqj-bet diamond_j"
                data-runner_name="Diamond J"
                data-runner_id=""
                data-market_id=""
                data-odds_val=""
                data-odd_type="LAGAI"
                data-match_id=""
                data-heroic_market_type=""
              >
                <img
                  alt="Diamond j"
                  src="http://sky7777.fun:80/assets/lottery/king_queen_jack/new_cards/diamond_j-fac69b65aa73033fba1d721f2fe22d68.png"
                />
                <span className="bet-coins">0</span>
              </div>
              <div
                className="card-section flex-column d-flex align-items-center kqj-bet club_j"
                data-runner_name="Club J"
                data-runner_id=""
                data-market_id=""
                data-odds_val=""
                data-odd_type="LAGAI"
                data-match_id=""
                data-heroic_market_type=""
              >
                <img
                  alt="Club j"
                  src="http://sky7777.fun:80/assets/lottery/king_queen_jack/new_cards/club_j-b3e8122c039e42ed0ef33a483f0ebf0f.png"
                />
                <span className="bet-coins">0</span>
              </div>
            </div>
          </div>
          <div className="text-center mrx-1 card-section-inner py-1 px-1 flex-1 bg-grey-light pb-2">
            <div className="f-bs text-white pb-2 d-flex align-items-center justify-content-center lh-14">
              Queens
            </div>
            <div className="d-flex card-outer justify-content-between">
              <div
                className="card-section flex-column d-flex align-items-center kqj-bet spade_q"
                data-runner_name="Spade Q"
                data-runner_id=""
                data-market_id=""
                data-odds_val=""
                data-odd_type="LAGAI"
                data-match_id=""
                data-heroic_market_type=""
              >
                <img
                  alt="Spade q"
                  src="http://sky7777.fun:80/assets/lottery/king_queen_jack/new_cards/spade_q-08671001ae5e7d5d12ba28dfcf6d199d.png"
                />
                <span className="bet-coins">0</span>
              </div>
              <div
                className="card-section flex-column d-flex align-items-center kqj-bet heart_q"
                data-runner_name="Heart Q"
                data-runner_id=""
                data-market_id=""
                data-odds_val=""
                data-odd_type="LAGAI"
                data-match_id=""
                data-heroic_market_type=""
              >
                <img
                  alt="Heart q"
                  src="http://sky7777.fun:80/assets/lottery/king_queen_jack/new_cards/heart_q-9e0457a3128b6555f97535a1d914571b.png"
                />
                <span className="bet-coins">0</span>
              </div>
              <div
                className="card-section flex-column d-flex align-items-center kqj-bet diamond_q"
                data-runner_name="Diamond Q"
                data-runner_id=""
                data-market_id=""
                data-odds_val=""
                data-odd_type="LAGAI"
                data-match_id=""
                data-heroic_market_type=""
              >
                <img
                  alt="Diamond q"
                  src="http://sky7777.fun:80/assets/lottery/king_queen_jack/new_cards/diamond_q-c02d98f870c4631c233c1c8d32ca035b.png"
                />
                <span className="bet-coins">0</span>
              </div>
              <div
                className="card-section flex-column d-flex align-items-center kqj-bet club_q"
                data-runner_name="Club Q"
                data-runner_id=""
                data-market_id=""
                data-odds_val=""
                data-odd_type="LAGAI"
                data-match_id=""
                data-heroic_market_type=""
              >
                <img
                  alt="Club q"
                  src="http://sky7777.fun:80/assets/lottery/king_queen_jack/new_cards/club_q-f44df324cf16e6496280ef88d10e92e9.png"
                />
                <span className="bet-coins">0</span>
              </div>
            </div>
          </div>
          <div className="text-center card-section-inner py-1 px-1 flex-1 bg-grey-light pb-2 br-r-5">
            <div className="f-bs text-white pb-2 d-flex align-items-center justify-content-center lh-14">
              Kings
            </div>
            <div className="d-flex card-outer justify-content-between">
              <div
                className="card-section flex-column d-flex align-items-center kqj-bet spade_k"
                data-runner_name="Spade K"
                data-runner_id=""
                data-market_id=""
                data-odds_val=""
                data-odd_type="LAGAI"
                data-match_id=""
                data-heroic_market_type=""
              >
                <img
                  alt="Spade k"
                  src="http://sky7777.fun:80/assets/lottery/king_queen_jack/new_cards/spade_k-b99e75c7c15d84f6fa77e3430a7137a7.png"
                />
                <span className="bet-coins">0</span>
              </div>
              <div
                className="card-section flex-column d-flex align-items-center kqj-bet heart_k"
                data-runner_name="Heart K"
                data-runner_id=""
                data-market_id=""
                data-odds_val=""
                data-odd_type="LAGAI"
                data-match_id=""
                data-heroic_market_type=""
              >
                <img
                  alt="Heart k"
                  src="http://sky7777.fun:80/assets/lottery/king_queen_jack/new_cards/heart_k-8066b8078ef6de33810e6bf92d9890fe.png"
                />
                <span className="bet-coins">0</span>
              </div>
              <div
                className="card-section flex-column d-flex align-items-center kqj-bet diamond_k"
                data-runner_name="Diamond K"
                data-runner_id=""
                data-market_id=""
                data-odds_val=""
                data-odd_type="LAGAI"
                data-match_id=""
                data-heroic_market_type=""
              >
                <img
                  alt="Diamond k"
                  src="http://sky7777.fun:80/assets/lottery/king_queen_jack/new_cards/diamond_k-a7391e57bb08d39910e4f66c58cf44b5.png"
                />
                <span className="bet-coins">0</span>
              </div>
              <div
                className="card-section flex-column d-flex align-items-center kqj-bet club_k"
                data-runner_name="Club K"
                data-runner_id=""
                data-market_id=""
                data-odds_val=""
                data-odd_type="LAGAI"
                data-match_id=""
                data-heroic_market_type=""
              >
                <img
                  alt="Club k"
                  src="http://sky7777.fun:80/assets/lottery/king_queen_jack/new_cards/club_k-d87db6ac52c214bf4403f944ae47b78c.png"
                />
                <span className="bet-coins">0</span>
              </div>
            </div>
          </div>
          <div className="flex pb-4 pt-4 tp-mob-mybets">
            <span className="mob-my-bets">MY BETS</span>
          </div>
        </div>
      </div>
      <div className="tp-bet-panel-container">
        <div className="tp-bet-panel tp-back-bet">
          <div className="tp-model-outer" id="modal-1">
            <div className="tp-model-inner flex aj-cen">
              <span className="close-modal"> ×</span>
              <span className="message" />
            </div>
          </div>
          <div className="tp-bet-title flex">
            <div className="tp-plc-bet l-text">PLACE BET</div>
            <div className="flex-1 tp-bal r-text">
              COINS : <span className="available_coins">1407.0</span>
            </div>
            <img
              alt="Delete"
              className="tp-bet-close"
              src="http://sky7777.fun:80/assets/lottery/teen_patti/delete-e4bbb4127020959669ed1616332cea82.svg"
            />
          </div>
          {/* Market Bet Amount Form start */}
          <div
            className="market-block teen_patti_form"
            style={{ float: "left" }}
          >
            <input
              className="odds_type"
              type="hidden"
              name="odds_type"
              defaultValue=""
            />
            <input
              className="runner_id"
              type="hidden"
              name="runner_id"
              defaultValue=""
            />
            <input
              className="odds_val"
              type="hidden"
              name="odds_val"
              defaultValue=""
            />
            <input
              className="match_id"
              type="hidden"
              name="match_id"
              defaultValue=""
            />
            <input
              className="market_id"
              type="hidden"
              name="market_id"
              defaultValue=""
            />
            <input
              className="heroic_market_type"
              type="hidden"
              name="heroic_market_type"
              defaultValue=""
            />
          </div>
          <div className="tp-bet-inner">
            <table className="tp-bet-table">
              <tbody>
                <tr className="heading-row">
                  <td aline="left" className="pl-10">
                    Bet For
                  </td>
                  <td align="right">Odds</td>
                </tr>
                <tr>
                  <td aline="left" className="pl-10 player_name" />
                  <td align="right" id="getodd" className="odds" />
                </tr>
              </tbody>
            </table>
            <div className="flex tp-input-outer">
              <input
                type="text"
                className="tp-bet-input"
                id="tp-bet-value"
                name="stack"
                defaultValue=""
                placeholder="Enter Amount"
                readOnly="readonly"
              />
            </div>
            <div className="flex tp-btn-outer bt-out-first">
              <div className="tp-btn flex-1 c-text" onclick="tpSuperValue(100)">
                100
              </div>
              <div className="tp-btn flex-1 c-text" onclick="tpSuperValue(500)">
                500
              </div>
              <div
                className="tp-btn flex-1 c-text"
                onclick="tpSuperValue(1000)"
              >
                1000
              </div>
              <div
                className="tp-btn flex-1 c-text"
                onclick="tpSuperValue(2000)"
              >
                2000
              </div>
              <div
                className="tp-btn flex-1 c-text"
                onclick="tpSuperValue(3000)"
              >
                3000
              </div>
            </div>
            <div className="flex tp-btn-outer bt-out-sec">
              <div
                className="tp-btn flex-1 c-text"
                onclick="tpSuperValue(4000)"
              >
                4000
              </div>
              <div
                className="tp-btn flex-1 c-text"
                onclick="tpSuperValue(5000)"
              >
                5000
              </div>
              <div
                className="tp-btn flex-1 c-text"
                onclick="tpSuperValue(10000)"
              >
                10000
              </div>
              <div
                className="tp-btn flex-1 c-text"
                onclick="tpSuperValue(20000)"
              >
                20000
              </div>
              <div
                className="tp-btn flex-1 c-text"
                onclick="tpSuperValue(25000)"
              >
                25000
              </div>
            </div>
            <div className="tp-num-outer">
              <div className="tp-num-row flex">
                <div className="tp-num" onclick="tpNum(1)">
                  1
                </div>
                <div className="tp-num" onclick="tpNum(2)">
                  2
                </div>
                <div className="tp-num" onclick="tpNum(3)">
                  3
                </div>
              </div>
              <div className="tp-num-row flex">
                <div className="tp-num" onclick="tpNum(4)">
                  4
                </div>
                <div className="tp-num" onclick="tpNum(5)">
                  5
                </div>
                <div className="tp-num" onclick="tpNum(6)">
                  6
                </div>
              </div>
              <div className="tp-num-row flex">
                <div className="tp-num" onclick="tpNum(7)">
                  7
                </div>
                <div className="tp-num" onclick="tpNum(8)">
                  8
                </div>
                <div className="tp-num" onclick="tpNum(9)">
                  9
                </div>
              </div>
              <div className="tp-num-row flex">
                <div className="tp-num tp-reset-icon" onclick="resetValue()">
                  RESET
                </div>
                <div className="tp-num" onclick="tpNum(0)">
                  0
                </div>
                <div
                  className="tp-num tp-delete-icon flex aj-cen"
                  id="clear-number"
                >
                  <img
                    alt="Backspace"
                    src="http://sky7777.fun:80/assets/lottery/teen_patti/backspace-346f03b40bcb55a0e22ee9d2d7ade97d.svg"
                  />
                </div>
              </div>
            </div>
            <div className="flex tp-btn-outer bt-out-third">
              <div
                className="tp-btn tp-reset flex-1 c-text"
                onclick="resetValue()"
              >
                RESET
              </div>
              <div className="tp-btn tp-bet-submit flex-1 c-text buttonload">
                <i className="bet_loader fa fa-circle-o-notch fa-spin hide-loader" />
                SUBMIT
              </div>
            </div>
          </div>
          {/* Market Bet Amount Form End */}
          {/* TP Bet History */}
          <div className="tp-history-outer">
            <div className="tp-mybets flex">My Bets</div>
            <div className="tp-bets-history">
              <table>
                <tbody>
                  <tr>
                    <th align="left">Bet for</th>
                    <th
                      align="center"
                      style={{ textAlign: "center !important" }}
                    >
                      Odds
                    </th>
                    <th align="right" style={{ textAlign: "right !important" }}>
                      Stake
                    </th>
                  </tr>
                </tbody>
                <tbody id="bets_info" />
              </table>
            </div>
          </div>
          {/* TP Bet History End */}
        </div>
        <div
          id="open-Modal"
          data-game-type="card-game"
          data-game-sub-type="card-game-kqj"
          data-casino-table-id={5}
          className="game-history-open"
        >
          History/Rules
        </div>
      </div>
      {/* tp-bet-panel close*/}
    </div>
  );
}
