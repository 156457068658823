import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  getCricketEventDetailsAPI,
  getCricketOddsByMatchIdAPI,
  getMatchDetailsCricketAPI,
  getMyBetsCricketAPI,
  placeCricketBetAPI,
  placeFancyCricketBetAPI,
} from "../service/cricket";
import {
  casinoPL,
  cricketBookMakerPL,
  cricketFancyPL,
  cricketMatchOddsPL,
} from "../utils/profitLoss";
import { getSportsScoreAPI } from "../service/AuthService";
import { applyFancyFor, getProfit } from "../utils/constants";
import toast from "react-hot-toast";

import axios from "axios";
import { getUserDataAPI } from "../service/UserService";

export default function PlayMatch() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [showDiv, setShowDiv] = useState(false);
  const [scoreData, setScoreData] = useState();
  const [numericOdds, setNumericOdds] = useState([]);
  const getScoreData = async () => {
    const { response, code } = await getSportsScoreAPI(id);
    if (code == 200) {
      setScoreData({
        data: { response },
      });
    }
  };
  const toggleDiv = () => {
    setShowDiv(!showDiv);
  };
  useEffect(() => {
    let interval;
    getScoreData();
    interval = setInterval(() => getScoreData(), 2000);
    return () => clearInterval(interval);
  }, [id]);
  const [oddQuery, setOddQuery] = useState();
  const betPlaceRefMobile = useRef(null);
  const [tvInnerHtml, setTvInnerHtml] = useState("");
  const [betTimer, setBetTimer] = useState(0);
  const [fancyPlusMinus, setFancyPlusMinus] = useState(null);
  const [timerInterval, setTimerInterval] = useState(null);

  const handleDefaultValueClick = (value, toAppend = false) => {
    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload: {
        ...selectedEventForBet,
        amount: toAppend
          ? String(
              selectedEventForBet?.amount ? selectedEventForBet.amount : ""
            ) + String(value)
          : value,
        profit: getProfit(value, game, selectedEventForBet).profit,
        loss: getProfit(value, game, selectedEventForBet).loss,
      },
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        betPlaceRefMobile.current &&
        !betPlaceRefMobile.current.contains(event.target)
      ) {
        dispatch({
          type: "gameData/setSelectedEventForBet",
          payload: null,
        });
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [betPlaceRefMobile]);

  var { id, game } = useParams();
  const location = useLocation();
  if (!id || !game) {
    var path = location.pathname.split("/");
    id = path[path.length - 1];
    game = path[path.length - 2];
  }
  const [gameEvent, setGameEvent] = useState({});
  const selectedEventForBet = useSelector(
    (state) => state.gameData.selectedEventForBet
  );

  const dispatch = useDispatch();
  const [userBets, setUserBets] = useState();

  var gameSettings_ = useSelector(
    (state) => state.gameData[game + "Settings"]
  );
  var gameSettings = useMemo(() => gameSettings_, [gameSettings_]);
  if (gameSettings && gameSettings[id]) {
    gameSettings = gameSettings[id];
  }
  const [loading, setIsLoading] = useState(false);
  const userData_ = useSelector((state) => state.account.userData);
  const userData = useMemo(() => userData_, [userData_]);
  const [fallOfWicketsOdds, setFallOfWicketsOdds] = useState([]);
  const [firstWicketOdds, setFirstWicketOdds] = useState([]);
  const [otherOdds, setOtherOdds] = useState([]);
  const [boundariesOdds, setBoundariesOdds] = useState([]);
  const [runsOdds, setRunsOdds] = useState([]);

  const getGameEventDetails = async () => {
    const { response } = await getCricketEventDetailsAPI(id);
    setGameEvent(response);
    return response;
  };
  const getOdds = async () => {
    const { response, code } = await getCricketOddsByMatchIdAPI(id);
    if (code == 200) {
      setOddQuery({
        data: {
          response: {
            ...response,
          },
        },
      });
      let normalFancy = response?.fancyOdds?.find(
        (item) => item?.gameType?.toLocaleLowerCase() == "normal"
      );

      let numericOdds_ = [];
      let fallOfWicketsOdds_ = [];
      let firstWicketOdds_ = [];
      let otherOdds_ = [];
      let boundariesOdds_ = [];
      let runsOdds_ = [];

      for (let i = 0; i < normalFancy?.oddDetailsDTOS?.length; i++) {
        if (
          checkIfStringIsNumber(
            normalFancy?.oddDetailsDTOS[i]?.runnerName?.split(" ")[0]
          ) ||
          normalFancy?.oddDetailsDTOS[i]?.runnerName
            ?.toLowerCase()
            ?.startsWith("fall of ") ||
          normalFancy?.oddDetailsDTOS[i]?.runnerName
            ?.toLowerCase()
            ?.includes("wkt runs")
        ) {
          if (
            checkIfStringIsNumber(
              normalFancy?.oddDetailsDTOS[i]?.runnerName?.split(" ")[0]
            )
          ) {
            numericOdds_.push(normalFancy?.oddDetailsDTOS[i]);
          }
          if (
            normalFancy?.oddDetailsDTOS[i]?.runnerName
              ?.toLowerCase()
              ?.startsWith("fall of ")
          ) {
            fallOfWicketsOdds_.push(normalFancy?.oddDetailsDTOS[i]);
          }
          if (
            normalFancy?.oddDetailsDTOS[i]?.runnerName
              ?.toLowerCase()
              ?.includes("wkt runs")
          ) {
            firstWicketOdds_.push(normalFancy?.oddDetailsDTOS[i]);
          }
        } else {
          otherOdds_.push(normalFancy?.oddDetailsDTOS[i]);
        }
      }
      numericOdds_ = numericOdds_?.sort((a, b) => {
        return a?.runnerName?.split(" ")[0] - b?.runnerName?.split(" ")[0];
      });
      let matchOverOdds_ = [];
      let partnershipOdds_ = [];
      let totalMatchRuns_ = [];

      for (let i = 0; i < otherOdds_.length; i++) {
        if (
          otherOdds_[i]?.runnerName?.toLowerCase()?.includes("boundaries")
        ) {
          boundariesOdds_.push(otherOdds_[i]);
          otherOdds_.splice(i, 1);
        }
        if (
          otherOdds_[i]?.runnerName?.toLowerCase()?.includes("wkt pship")
        ) {
          partnershipOdds_.push(otherOdds_[i]);
          otherOdds_.splice(i, 1);
        }
        if (
          otherOdds_[i]?.runnerName
            ?.toLowerCase()
            ?.includes("match 1st over run")
        ) {
          matchOverOdds_.push(otherOdds_[i]);
          otherOdds_.splice(i, 1);
        }
        if (
          otherOdds_[i]?.runnerName
            ?.toLowerCase()
            ?.includes("total match runs")
        ) {
          totalMatchRuns_.push(otherOdds_[i]);
          otherOdds_.splice(i, 1);
        }
      }
      setNumericOdds([...matchOverOdds_, ...numericOdds_]);
      setFallOfWicketsOdds(fallOfWicketsOdds_);
      setFirstWicketOdds(
        firstWicketOdds_.sort((a, b) => {
          return a?.runnerName?.split(" ")[1] - b?.runnerName?.split(" ")[1];
        })
      );
      setBoundariesOdds([...boundariesOdds_, ...totalMatchRuns_]);
      setOtherOdds([...otherOdds_, ...partnershipOdds_]);
    }
  };
  useEffect(() => {
    let interval;
    getOdds();
    interval = setInterval(() => getOdds(), 1000);

    return () => clearInterval(interval);
  }, [id]);
  useEffect(() => {
    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload: null,
    });
  }, [game, id]);

  const getCricketMatchDetails = async () => {
    const { response, code } = await getMatchDetailsCricketAPI(id);

    dispatch({
      type: "gameData/setMatchSettings",
      payload: {
        game,
        settings: response,
        eventId: id,
      },
    });
  };

  const startBetTimer = () => {
    let timer = 7;
    setBetTimer(timer);

    var y = setInterval(function() {
                                     timer =
                                       timer -
                                       1;
                                     setBetTimer(
                                       timer
                                     );

                                     if (
                                       timer ==
                                       0
                                     ) {
                                       dispatch(
                                         {
                                           type:
                                             "gameData/setSelectedEventForBet",
                                           payload: null,
                                         }
                                       );
                                       clearInterval(
                                         y
                                       );
                                     }
                                   }, 1000);
    setTimerInterval(y);
  };
  const fancySetBet = (data, type, market, gameType) => {
    startBetTimer();
    let matchData = oddQuery?.data?.response?.fancyOdds?.find(
      (item) => item?.gameType?.toLocaleLowerCase() == gameType
    );
    if (!matchData) return;

    let selectedEventForBet = {
      eventId: id,
      amount: 0,
      marketId: data?.marketId,
      marketType: market,
      marketName: matchData?.marketName,
      type: type?.startsWith("lay") ? "lay" : "back",
      isBookmaker: false,
      selectedRate: data[type?.startsWith("lay") ? "laySize1" : "backSize1"],
      selectedOdd: data[type],
      selectedId: data.selectionId,
      profit: getProfit(0, game, data).profit,
      loss: getProfit(0, game, data).loss,
      amount: null,
      runnerName: data.runnerName,
      isYesAndNo: true,
      isFancy: true,
      gameType: gameType,
    };
    if (gameType == "fancy1") {
      selectedEventForBet["selectedRate"] = 0.9;
      selectedEventForBet["selectedOdd"] = data[type];
      selectedEventForBet.profit = cricketMatchOddsPL(
        data[type],
        0,
        selectedEventForBet.type
      ).profit;

      selectedEventForBet.loss = cricketMatchOddsPL(
        data[type],
        0,
        selectedEventForBet.type
      ).loss;
    }
    if (selectedEventForBet?.selectedOdd == 0) {
      return;
    }
    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload: selectedEventForBet,
    });
  };
  const bookmakerSetBet = (data, type, market, gameType) => {
    let matchData = oddQuery?.data?.response?.bookMakerOdds[0]?.bm1;
    if (!matchData) return;
    startBetTimer();
    let selectedEventForBet = {
      eventId: id,
      amount: 0,
      marketId: matchData?.marketId,
      marketType: market,
      marketName: matchData?.marketName,
      type: type?.startsWith("lay") ? "lay" : "back",
      isBookmaker: true,
      selectedRate: data[type?.startsWith("lay") ? "laySize1" : "backSize1"],
      selectedOdd: data[type],
      selectedId: data.selectionId,
      profit: getProfit(0, game, data).profit,
      loss: getProfit(0, game, data).loss,
      amount: null,
      runnerName: data.runnerName,
      isYesAndNo: false,
    };
    if (selectedEventForBet?.selectedOdd == 0) {
      return;
    }
    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload: selectedEventForBet,
    });
  };

  const myBets = async () => {
    try {
      const { response, code, extra } = await getMyBetsCricketAPI(id);
      if (code == 200) {
        setUserBets(response);
        setFancyPlusMinus(extra?.fancyPlusMinus);
      }
    } catch (err) {
      setUserBets([]);
    }
  };

  const placeBet = async () => {
    let delay = 1500;
    setIsLoading(true);

    let selectedAmount_ = selectedEventForBet?.amount;
    if (selectedEventForBet.selectedOdd <= 0) {
      toast.error("Please select a Valid Odd");
      setIsLoading(false);
      return;
    }
    if (game == "cricket" || game == "tennis" || game == "soccer") {
      if (
        (selectedEventForBet.marketName == "MATCH_ODDS" &&
          gameSettings?.minStack > selectedAmount_) ||
        (selectedEventForBet.marketName == "BOOKMAKER_ODDS_2" &&
          gameSettings?.minStack > selectedAmount_) ||
        (selectedEventForBet.marketName == "FANCY_ODDS" &&
          gameSettings?.sessionMinStack > selectedAmount_) ||
        (selectedEventForBet.marketName == "FANCY_ODDS_2" &&
          gameSettings?.sessionMinStack > selectedAmount_)
      ) {
        toast.error(
          `Minimum bet amount is ${
            selectedEventForBet.marketName == "FANCY_ODDS_2"
              ? gameSettings?.sessionMinStack
              : selectedEventForBet.marketName == "FANCY_ODDS"
              ? gameSettings?.sessionMinStack
              : selectedEventForBet.marketName == "BOOKMAKER_ODDS_2"
              ? gameSettings?.bookmakerMinStack
              : gameSettings?.minStack
          }`
        );
        setIsLoading(false);
        return;
      }
      if (
        (selectedEventForBet.marketName == "MATCH_ODDS" &&
          gameSettings?.maxStack < selectedAmount_) ||
        (selectedEventForBet.marketName == "BOOKMAKER_ODDS_2" &&
          gameSettings?.bookmakerMaxStack < selectedAmount_) ||
        (selectedEventForBet.marketName == "FANCY_ODDS" &&
          gameSettings?.sessionMaxStack < selectedAmount_) ||
        (selectedEventForBet.marketName == "FANCY_ODDS_2" &&
          gameSettings?.sessionMaxStack < selectedAmount_)
      ) {
        toast.error(
          `Maximum bet amount is ${
            selectedEventForBet.marketName == "FANCY_ODDS_2"
              ? gameSettings?.sessionMaxStack
              : selectedEventForBet.marketName == "FANCY_ODDS"
              ? gameSettings?.sessionMaxStack
              : selectedEventForBet.marketName == "BOOKMAKER_ODDS_2"
              ? gameSettings?.bookmakerMaxStack
              : gameSettings?.maxStack
          }`
        );
        setIsLoading(false);
        return;
      }
    }
    if (selectedEventForBet?.isFancy) {
      delay = 1200;
    }
    let profit;
    let loss;
    setTimeout(async () => {
      if (game == "cricket" && selectedEventForBet.isBookmaker) {
        profit = cricketBookMakerPL(
          selectedEventForBet.selectedOdd,
          selectedAmount_,
          selectedEventForBet.type
        ).profit;
        loss = cricketBookMakerPL(
          selectedEventForBet.selectedOdd,
          selectedAmount_,
          selectedEventForBet.type
        ).loss;
      } else if (game == "tennis" || game == "soccer") {
        profit = casinoPL(
          selectedEventForBet.selectedOdd,
          selectedAmount_,
          selectedEventForBet.type
        ).profit;
        loss = casinoPL(
          selectedEventForBet.selectedOdd,
          selectedAmount_,
          selectedEventForBet.type
        ).loss;
      } else {
        profit = cricketMatchOddsPL(
          selectedEventForBet.selectedOdd,
          selectedAmount_,
          selectedEventForBet.type
        ).profit;
        loss = cricketMatchOddsPL(
          selectedEventForBet.selectedOdd,
          selectedAmount_,
          selectedEventForBet.type
        ).loss;
      }
      let payload = {
        amount: selectedAmount_,
        profit: parseFloat(profit).toFixed(2),
        loss: parseFloat(loss).toFixed(2),
        marketId: selectedEventForBet.marketId,
        selectionId: selectedEventForBet.selectedId,
        type: selectedEventForBet.type,
        eventId: parseInt(selectedEventForBet.eventId),
        runnerName: selectedEventForBet.runnerName,
        gameType:
          selectedEventForBet.marketName != "MATCH_ODDS" ||
          selectedEventForBet.marketName != "BOOKMAKER_ODDS_1" ||
          selectedEventForBet.marketName != "BOOKMAKER_ODDS_2"
            ? selectedEventForBet.gameType
            : selectedEventForBet.marketName,
        marketName: selectedEventForBet.marketName,
      };
      if (selectedEventForBet.isBookmaker && selectedEventForBet?.layRate) {
        payload["layRate"] = selectedEventForBet.selectedOdd;
      } else if (
        selectedEventForBet?.isBookmaker &&
        selectedEventForBet?.backRate
      ) {
        payload["backRate"] = selectedEventForBet.selectedOdd;
      }

      if (selectedEventForBet.type == "back") {
        payload["back"] = parseFloat(selectedEventForBet.selectedOdd);
        payload["backRate"] = parseFloat(selectedEventForBet.selectedOdd);
      } else {
        payload["layRate"] = parseFloat(selectedEventForBet.selectedOdd);
        payload["lay"] = parseFloat(selectedEventForBet.selectedOdd);
      }

      await new Promise((resolve) => setTimeout(resolve, delay));

      if (game == "cricket" && !selectedEventForBet.isFancy) {
        const { response, code, data } = await placeCricketBetAPI(
          payload,
          userData.token
        );
        if (code == 200) {
          toast.success("Bet Placed");
          setBetTimer(0);
          clearInterval(timerInterval);
        } else if (code == 500) {
          toast.error(response);
        } else if (code == "ERR_BAD_RESPONSE") {
          toast.error("Something Went Wrong");
        } else {
          toast.error("Something Went Wrong");
        }
      } else if (game == "cricket" && selectedEventForBet?.isFancy) {
        let fancyPayload = {
          amount: selectedAmount_,
          eventId: parseInt(selectedEventForBet.eventId),
          marketId: selectedEventForBet.marketId,
          marketName:
            selectedEventForBet.marketName != "MATCH_ODDS" ||
            selectedEventForBet.marketName != "BOOKMAKER_ODDS_1" ||
            selectedEventForBet.marketName != "BOOKMAKER_ODDS_2"
              ? selectedEventForBet.gameType
              : selectedEventForBet.marketName,
          gameType: selectedEventForBet.gameType,
        };

        if (selectedEventForBet.type === "lay") {
          fancyPayload["layRate"] = selectedEventForBet.selectedRate;
          fancyPayload.oddsLay = selectedEventForBet.selectedOdd;
          if (selectedEventForBet.gameType == "fancy1") {
            fancyPayload["layRate"] = selectedEventForBet.selectedOdd;
            fancyPayload.oddsLay = selectedEventForBet.selectedOdd;
          }
        } else {
          fancyPayload["backRate"] = selectedEventForBet.selectedRate;
          fancyPayload.oddsBack = selectedEventForBet.selectedOdd;
          if (selectedEventForBet.gameType == "fancy1") {
            fancyPayload["backRate"] = selectedEventForBet.selectedOdd;
            fancyPayload.oddsBack = selectedEventForBet.selectedOdd;
          }
        }

        if (
          applyFancyFor.find(
            (item) =>
              item?.toLowerCase() ==
              selectedEventForBet?.gameType?.toLowerCase()
          )
        ) {
          if (selectedEventForBet?.type == "lay") {
            if (selectedEventForBet?.gameType == "fancy1") {
              fancyPayload.profit = cricketMatchOddsPL(
                selectedEventForBet.selectedOdd,
                selectedAmount_,
                selectedEventForBet.type
              ).loss;
              fancyPayload.loss = cricketMatchOddsPL(
                selectedEventForBet.selectedOdd,
                selectedAmount_,
                selectedEventForBet.type
              ).profit;
            } else {
              fancyPayload.profit = cricketFancyPL(
                selectedEventForBet.selectedRate,
                selectedAmount_,
                selectedEventForBet.type
              ).loss;
              fancyPayload.loss = cricketFancyPL(
                selectedEventForBet.selectedRate,
                selectedAmount_,
                selectedEventForBet.type
              ).profit;
            }
          } else {
            if (selectedEventForBet?.gameType == "fancy1") {
              fancyPayload.profit = cricketMatchOddsPL(
                selectedEventForBet.selectedOdd,
                selectedAmount_,
                selectedEventForBet.type
              ).profit;
              fancyPayload.loss = cricketMatchOddsPL(
                selectedEventForBet.selectedOdd,
                selectedAmount_,
                selectedEventForBet.type
              ).loss;
            } else {
              fancyPayload.profit = cricketFancyPL(
                selectedEventForBet.selectedRate,
                selectedAmount_,
                selectedEventForBet.type
              ).profit;
              fancyPayload.loss = cricketFancyPL(
                selectedEventForBet.selectedRate,
                selectedAmount_,
                selectedEventForBet.type
              ).loss;
            }
          }
        } else {
          fancyPayload.profit = cricketMatchOddsPL(
            selectedEventForBet.selectedOdd,
            selectedAmount_,
            selectedEventForBet.type
          ).profit;
          fancyPayload.loss = cricketMatchOddsPL(
            selectedEventForBet.selectedOdd,
            selectedAmount_,
            selectedEventForBet.type
          ).loss;
          fancyPayload.selectionId = selectedEventForBet.selectedId;
          fancyPayload.runnerName = selectedEventForBet.runnerName;
          if (selectedEventForBet.type === "lay") {
            fancyPayload["layRate"] = selectedEventForBet.selectedOdd;
          } else {
            fancyPayload["backRate"] = selectedEventForBet.selectedOdd;
          }
        }

        const { response, code, data } = await placeFancyCricketBetAPI(
          fancyPayload,
          userData.token
        );
        if (code == 200) {
          toast.success("Bet Placed successfully");
          clearInterval(timerInterval);
          setIsLoading(false);
          dispatch({
            type: "gameData/setSelectedEventForBet",
            payload: null,
          });
          setBetTimer(0);
        } else if (code == 500) {
          toast.error(response);
        } else if (code == "ERR_BAD_RESPONSE") {
          setIsLoading(false);
          toast.error("Something Went wrong");
        } else {
          toast.error("Something Went Wrong");
        }
      }
      setIsLoading(false);
      getUserDataAPI(userData?.id);
      dispatch({
        type: "gameData/setSelectedEventForBet",
        payload: null,
      });
    }, 1000);
  };

  const getTv = () =>
    getGameEventDetails().then((gameEvent_) => {
      try {
        axios
          .post(
            `https://bababetbook.in/tv/hlive.php?eid=${gameEvent_?.eventId}`
          )
          .then((res) => {
            setTvInnerHtml(res.data);
          });
      } catch (e) {}
    });
  const checkIfStringIsNumber = (str) => {
    return !isNaN(str) && !isNaN(parseFloat(str));
  };
  useEffect(() => {
    getTv().then(() =>
      myBets().then(() => {
        getCricketMatchDetails().then(() => {
          setInterval(() => {
            myBets().then(() => {
              getCricketMatchDetails();
            });
          }, 5000);
        });
      })
    );
  }, [game, id]);
  return (
    <div>
      {loading ? (
        <>
          <div className="loader">
            <img
              src={require("../assets/logo2.png")}
              alt="Loader"
              class="flip-vertical-right"
            />
          </div>
        </>
      ) : null}
      <div className="position-relative">
        <table
          className="table"
          style={{
            marginBottom: "-4px",
            marginTop: "-5px",
          }}
        >
          <tbody className="lgaai">
            <tr>
              <th width="50%">
                <div
                  className="toggle-tv-old lgaai"
                  onClick={toggleDiv}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <p className="active text-left text-light m-0" id="tvBtn">
                    <img
                      src="/assets/images/tv.jpeg"
                      alt=""
                      style={{
                        width: "23px",
                      }}
                    />
                    Tv
                  </p>
                </div>
              </th>
            </tr>
          </tbody>
        </table>

        {showDiv && (
          <div>
            <iframe
              src={`https://param.apigang.com/api/v3/livetv2.php?eventId=${
                gameEvent?.eventId
              }`}
              title="Live Match"
              className="match-tv"
            />
          </div>
        )}

        {
          <iframe
            style={{
              width: "100%",
              height: "auto",
            }}
            src={`https://score.proexch.in/#/score1/${gameEvent?.eventId}`}
          />
        }

        <table
          style={{
            marginBottom: "0px",
          }}
          className="match-bets-old table table-bordered"
        >
          <thead className="lgaai">
            <tr>
              <th width="46%">
                Team
                <img
                  onClick={handleOpen}
                  src="/assets/images/info.png"
                  className="mtachImgInfo"
                  tilte="Rules (Bookmaker)"
                  alt="Rules (Bookmaker)"
                  id="BOOKMAKER"
                />
                <p className="m-0"> {gameSettings?.maxStack}</p>
              </th>
              <th
                width="22%"
                align="center"
                valign="middle"
                bgcolor="#002D5B"
                className="vertical-middle FontTextWhite10px"
              >
                Lagai
              </th>
              <th
                width="22%"
                align="center"
                valign="middle"
                bgcolor="#002D5B"
                className="vertical-middle FontTextWhite10px"
              >
                Khai
              </th>
            </tr>
          </thead>
          <tbody>
            {oddQuery?.data?.response &&
              oddQuery?.data?.response?.bookMakerOdds[0]?.bm1?.oddDetailsDTOS.map(
                (match, index) => (
                  <tr className="bet-table">
                    <th className="d-flex justify-content-center align-items-center">
                      {match?.runnerName}:{" "}
                      <b
                        className={`mx-2 ${
                          gameSettings?.plExposureResponseDTOs
                            ?.find(
                              (item) =>
                                item.marketIdExternal ==
                                oddQuery?.data?.response?.bookMakerOdds[0]
                                  ?.bm1?.marketId
                            )
                            ?.runnerPlExposureDTOS?.find(
                              (pl) => pl.selectionId == match?.selectionId
                            )?.exposure > 0
                            ? "text-primary"
                            : "text-danger"
                        }`}
                      >
                        {" "}
                        {gameSettings?.plExposureResponseDTOs
                          ?.find(
                            (item) =>
                              item.marketIdExternal ==
                              oddQuery?.data?.response?.bookMakerOdds[0]?.bm1
                                ?.marketId
                          )
                          ?.runnerPlExposureDTOS?.find(
                            (pl) => pl.selectionId == match?.selectionId
                          )?.exposure &&
                          parseFloat(
                            gameSettings?.plExposureResponseDTOs
                              ?.find(
                                (item) =>
                                  item.marketIdExternal ==
                                  oddQuery?.data?.response?.bookMakerOdds[0]
                                    ?.bm1?.marketId
                              )
                              ?.runnerPlExposureDTOS?.find(
                                (pl) => pl.selectionId == match?.selectionId
                              )?.exposure
                          ).toFixed(2)}
                      </b>
                    </th>
                    {match?.status == "SUSPENDED" && (
                      <th className="suspended">SUSPENDED</th>
                    )}
                    <th
                      className="text-dark text-center font-17 teamClrBlue"
                      onClick={() => {
                        bookmakerSetBet(match, "back1", match?.marketName);
                      }}
                    >
                      <span>
                        {" "}
                        {parseFloat(match?.back1 / 100).toFixed(2)}
                      </span>
                    </th>
                    <th
                      className="text-dark font-17 teamClrPink"
                      onClick={() => {
                        bookmakerSetBet(match, "lay1", match?.marketName);
                      }}
                    >
                      <span> {parseFloat(match?.lay1 / 100).toFixed(2)}</span>
                    </th>
                  </tr>
                )
              )}
          </tbody>
        </table>

        {oddQuery?.data?.response?.fancyOdds?.find(
          (item) => item?.gameType?.toLocaleLowerCase() == "fancy1"
        )?.oddDetailsDTOS?.length > 0 && (
          <table
            style={{
              marginTop: 5,
              marginBottom: 5,
            }}
            className="match-bets-old table table-bordered"
          >
            <thead className="lgaai">
              <tr>
                <th width="46%">
                  Toss
                  <img
                    onClick={handleOpen}
                    src="/assets/images/info.png"
                    className="mtachImgInfo"
                    tilte="Rules (Bookmaker)"
                    alt="Rules (Bookmaker)"
                    id="BOOKMAKER"
                  />
                </th>

                <th
                  width="22%"
                  align="center"
                  valign="middle"
                  bgcolor="#002D5B"
                  className="vertical-middle FontTextWhite10px"
                >
                  YES
                </th>
              </tr>
            </thead>
            <tbody>
              {oddQuery?.data?.response?.fancyOdds
                ?.find(
                  (item) => item?.gameType?.toLocaleLowerCase() == "fancy1"
                )
                ?.oddDetailsDTOS?.map((match) => (
                  <tr className="bet-table">
                    <th>
                      {match?.runnerName}
                      <br />
                      <small className="text-success">
                        <b>{match?.maxLimit}</b>
                      </small>
                    </th>
                    <th
                      className="text-dark text-center font-17 teamClrBlue"
                      onClick={() =>
                        fancySetBet(match, "back1", "fancyOdds", "fancy1")
                      }
                    >
                      {match?.status == "SUSPENDED" && (
                        <th className="suspended-fancy-toss">SUSPENDED</th>
                      )}
                      <span> {parseInt(match?.back1)}</span>
                      <br />
                      {parseFloat(0.9).toFixed(2)}
                    </th>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
        {/*  */}
        <div
          style={{
            marginTop: 1,
            display: "flex",
            justifyContent: "space-between",
          }}
          className="session-plus-minus mb-2 session-style"
        >
          <p>SESSION </p>
          {fancyPlusMinus && (
            <p
              style={{
                color:
                  fancyPlusMinus < 0 ? "red !important" : "green !important",
              }}
            >
              {fancyPlusMinus}
            </p>
          )}
        </div>
        <table className="match-bets-old table table-bordered">
          <thead className="lgaai">
            <tr>
              <th width="46%">
                Session
                <img
                  onClick={handleOpen}
                  src="/assets/images/info.png"
                  className="mtachImgInfo"
                  tilte="Rules (Bookmaker)"
                  alt="Rules (Bookmaker)"
                  id="BOOKMAKER"
                />
              </th>
              <th
                width="22%"
                align="center"
                valign="middle"
                bgcolor="#002D5B"
                className="vertical-middle FontTextWhite10px"
              >
                NO
              </th>
              <th
                width="22%"
                align="center"
                valign="middle"
                bgcolor="#002D5B"
                className="vertical-middle FontTextWhite10px"
              >
                Yes
              </th>
            </tr>
          </thead>
          <tbody>
            {numericOdds?.map((match) => (
              <tr className="bet-table">
                <th>
                  {match?.runnerName}
                  <br />
                  <small className="text-success">
                    <b>{match?.maxLimit}</b>
                  </small>
                </th>

                <th
                  className="text-dark text-center font-17 teamClrPink"
                  onClick={() =>
                    fancySetBet(match, "lay1", "fancyOdds", "normal")
                  }
                >
                  {match?.status == "SUSPENDED" && (
                    <th className="suspended-fancy">SUSPENDED</th>
                  )}
                  <span>{parseInt(match?.lay1)}</span>
                  <br /> {parseFloat(match?.laySize1 / 100).toFixed(2)}
                </th>

                <th
                  className="text-dark text-center font-17 teamClrBlue"
                  onClick={() =>
                    fancySetBet(match, "back1", "fancyOdds", "normal")
                  }
                >
                  <span> {parseInt(match?.back1)}</span>
                  <br />
                  {parseFloat(match?.backSize1 / 100).toFixed(2)}
                </th>
              </tr>
            ))}
            {fallOfWicketsOdds?.map((match) => (
              <tr className="bet-table">
                <th>
                  {match?.runnerName}
                  <br />
                  <small className="text-success">
                    <b>{match?.maxLimit}</b>
                  </small>
                </th>

                <th
                  className="text-dark text-center font-17 teamClrPink"
                  onClick={() =>
                    fancySetBet(match, "lay1", "fancyOdds", "normal")
                  }
                >
                  {match?.status == "SUSPENDED" && (
                    <th className="suspended-fancy">SUSPENDED</th>
                  )}
                  <span>{parseInt(match?.lay1)}</span>
                  <br /> {parseFloat(match?.laySize1 / 100).toFixed(2)}
                </th>

                <th
                  className="text-dark text-center font-17 teamClrBlue"
                  onClick={() =>
                    fancySetBet(match, "back1", "fancyOdds", "normal")
                  }
                >
                  <span> {parseInt(match?.back1)}</span>
                  <br />
                  {parseFloat(match?.backSize1 / 100).toFixed(2)}
                </th>
              </tr>
            ))}
            {firstWicketOdds?.map((match) => (
              <tr className="bet-table">
                <th>
                  {match?.runnerName}
                  <br />
                  <small className="text-success">
                    <b>{match?.maxLimit}</b>
                  </small>
                </th>

                <th
                  className="text-dark text-center font-17 teamClrPink"
                  onClick={() =>
                    fancySetBet(match, "lay1", "fancyOdds", "normal")
                  }
                >
                  {match?.status == "SUSPENDED" && (
                    <th className="suspended-fancy">SUSPENDED</th>
                  )}
                  <span>{parseInt(match?.lay1)}</span>
                  <br /> {parseFloat(match?.laySize1 / 100).toFixed(2)}
                </th>

                <th
                  className="text-dark text-center font-17 teamClrBlue"
                  onClick={() =>
                    fancySetBet(match, "back1", "fancyOdds", "normal")
                  }
                >
                  <span> {parseInt(match?.back1)}</span>
                  <br />
                  {parseFloat(match?.backSize1 / 100).toFixed(2)}
                </th>
              </tr>
            ))}
            {otherOdds?.map((match) => (
              <tr className="bet-table">
                <th>
                  {match?.runnerName}
                  <br />
                  <small className="text-success">
                    <b>{match?.maxLimit}</b>
                  </small>
                </th>

                <th
                  className="text-dark text-center font-17 teamClrPink"
                  onClick={() =>
                    fancySetBet(match, "lay1", "fancyOdds", "normal")
                  }
                >
                  {match?.status == "SUSPENDED" && (
                    <th className="suspended-fancy">SUSPENDED</th>
                  )}
                  <span>{parseInt(match?.lay1)}</span>
                  <br /> {parseFloat(match?.laySize1 / 100).toFixed(2)}
                </th>

                <th
                  className="text-dark text-center font-17 teamClrBlue"
                  onClick={() =>
                    fancySetBet(match, "back1", "fancyOdds", "normal")
                  }
                >
                  <span> {parseInt(match?.back1)}</span>
                  <br />
                  {parseFloat(match?.backSize1 / 100).toFixed(2)}
                </th>
              </tr>
            ))}
            {boundariesOdds?.map((match) => (
              <tr className="bet-table">
                <th>
                  {match?.runnerName}
                  <br />
                  <small className="text-success">
                    <b>{match?.maxLimit}</b>
                  </small>
                </th>

                <th
                  className="text-dark text-center font-17 teamClrPink"
                  onClick={() =>
                    fancySetBet(match, "lay1", "fancyOdds", "normal")
                  }
                >
                  {match?.status == "SUSPENDED" && (
                    <th className="suspended-fancy">SUSPENDED</th>
                  )}
                  <span>{parseInt(match?.lay1)}</span>
                  <br /> {parseFloat(match?.laySize1 / 100).toFixed(2)}
                </th>

                <th
                  className="text-dark text-center font-17 teamClrBlue"
                  onClick={() =>
                    fancySetBet(match, "back1", "fancyOdds", "normal")
                  }
                >
                  <span> {parseInt(match?.back1)}</span>
                  <br />
                  {parseFloat(match?.backSize1 / 100).toFixed(2)}
                </th>
              </tr>
            ))}
          </tbody>
        </table>

        {/*  */}
        {
          <table
            width="100%"
            border="0"
            cellpadding="2"
            cellspacing="2"
            className="old-match-bets text-center"
            style={{
              marginTop: "-15px",
            }}
          >
            <tbody>
              <tr className="text-white table-bordered">
                <th
                  height="25"
                  align="center"
                  valign="middle"
                  bgcolor="#002D5B"
                  className="FontTextWhite10px"
                >
                  Sr.
                </th>
                <th
                  align="right"
                  valign="middle"
                  bgcolor="#002D5B"
                  className="FontTextWhite10px"
                >
                  {" "}
                  Rate
                </th>
                <th
                  align="right"
                  valign="middle"
                  bgcolor="#002D5B"
                  className="FontTextWhite10px"
                >
                  Amount
                </th>
                <th
                  align="center"
                  valign="middle"
                  bgcolor="#002D5B"
                  className="FontTextWhite10px"
                >
                  {" "}
                  Mode
                </th>
                <th
                  align="left"
                  valign="middle"
                  bgcolor="#002D5B"
                  className="FontTextWhite10px"
                >
                  Team
                </th>
              </tr>
              {userBets &&
                userBets
                  ?.filter((item) => item?.marketName == "BOOKMAKER_ODDS_1")
                  .map((item, index) => (
                    <tr
                      style={{
                        borderBottom: "1px solid black",
                      }}
                    >
                      <td>{index + 1}</td>
                      <td>
                        {item?.backRate ? item?.backRate : item?.layRate}
                      </td>
                      <td>{item?.amount}</td>
                      <td>{item?.back ? "LAGAI" : "KHAI"}</td>
                      <td> {item?.runnerName} </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        }

        {/*  */}

        {
          <div className="table-responsive">
            <table
              width="100%"
              className="old-match-bets"
              border="0"
              cellspacing="2"
              cellpadding="2"
            >
              <thead>
                <tr className="text-white">
                  <th
                    height="25"
                    align="center"
                    bgcolor="#002D5B"
                    className="FontTextWhite10px"
                  >
                    Sr.
                  </th>
                  <th
                    align="left"
                    bgcolor="#002D5B"
                    className="FontTextWhite10px"
                  >
                    Session
                  </th>
                  <th
                    align="right"
                    valign="middle"
                    bgcolor="#002D5B"
                    className="FontTextWhite10px"
                  >
                    Rate
                  </th>
                  <th
                    align="right"
                    valign="middle"
                    bgcolor="#002D5B"
                    className="FontTextWhite10px"
                  >
                    Amount
                  </th>
                  <th
                    align="right"
                    bgcolor="#002D5B"
                    className="FontTextWhite10px"
                  >
                    Run
                  </th>
                  <th
                    align="center"
                    bgcolor="#002D5B"
                    className="FontTextWhite10px"
                  >
                    Mode
                  </th>
                  <th
                    align="center"
                    bgcolor="#002D5B"
                    className="FontTextWhite10px"
                  >
                    Dec
                  </th>
                </tr>
              </thead>
              <tbody>
                {userBets &&
                  userBets
                    ?.filter((item) => item?.marketName != "BOOKMAKER_ODDS_1")
                    ?.map((item, index) => (
                      <tr
                        style={{
                          borderBottom: "1px solid black",
                        }}
                      >
                        <td>{index + 1}</td>
                        <td>{item?.runnerName}</td>
                        <td>
                          {item?.backRate ? item?.backRate : item?.layRate}
                        </td>
                        <td>{item?.amount}</td>
                        <td>{item?.back ? item?.back : item?.lay}</td>
                        <td>{item?.back ? "YES" : "NO"}</td>
                        <td>{item?.result}</td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        }

        <Link className="btn btn-primary w-100 backbutton" to="/in-play">
          Back to Main Menu
        </Link>
      </div>

      {/* Modal */}
      {open && (
        <Modal
          show={open}
          centered
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-title h4">
                  <h2
                    style={{
                      fontWeight: "bold",
                      fontSize: "25px",
                    }}
                  >
                    {" "}
                    RULES
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      (BOOKMAKER){" "}
                    </span>
                  </h2>
                </div>
                <button
                  type="button"
                  onClick={handleClose}
                  className="btn-close"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div
                  className="matchRules"
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  <div className="matchRules">
                    <h5> CRICKET </h5>
                    <p> 1.मैच के टाई होने पर सभी सौदे मान्य किए जायेगे,</p>
                    <p>
                      {" "}
                      2. सभी एडवांस फैंसी टॉस के कुछ देर पहले ससपेंड कर दी
                      जाएगी ,{" "}
                    </p>
                    <p>
                      {" "}
                      3. यदि किसी तकनीकी खराबी के कारन कोई सेशन या फैंसी ID से
                      हट जाती है और दुबारा ID पर नहीं आती है तो उस सेशन पर किए
                      गए सभी सौदे मान्य किए जायेगे,{" "}
                    </p>
                    <p>
                      4. यदि किसी सेशन या फैंसी के रेट गलत चल जाते है तो गलत
                      रेट पर किए गए सौदे हटा दिए जायेगे,
                    </p>
                    <p>
                      5. स्कोर बोर्ड और टीवी थर्ड पार्टी सोर्स है अगर स्कोर
                      स्लो और गलत चलता है तो इस मे कम्पनी की कोई ज़िम्मेदारी
                      नहीं है कृपया सौदा अपने हिसाब से करे,
                    </p>
                    <p className="redP">
                      6. यदि कोई क्लाइंट ग्राउंड कमेंर्टी से एक ही IP से BOOT
                      से VPN से एक है टाइम मे एक से सौदे करता है तो करता है तो
                      केवल जीत वाले सौदे हटा दिए जाएंगे , उदहारण:-(
                      <span className="redSpan">
                        6 ओवर सेशन मै 44 YES और 48 NOT करता है और रन 43 बनते
                        है तो 48 वाले सभी सौदे हटा दिए जा सकते है,
                      </span>
                      )
                    </p>
                    <p>
                      7. पेनल्टी के रन बर्तमान मे चल रहे किसी भी सेशन पर मान्य
                      नहीं किए जाते है,
                    </p>
                    <p>
                      8. यदि बर्तमान मे सेशन चल रहे हो और टीम (DLS) या किसी भी
                      कारन से जीत जाती है तो ओवर वाले सेशन जो कम्पलीट नहीं हुए
                      है वो रद्द कर दिया जायेगा और एक्स्ट्रा सेशन (
                      <span className="redSpan">
                        विकेट पार्टनरशिप ,प्लेयर के रन,एक्सटरा फैंसी) सभी पास
                        किआ जायेगा,
                      </span>
                    </p>
                    <p>
                      9.प्लेयर के 35 रन पर रिटायर्ड हर्ट या इंजर्ड होने पर
                      प्लेयर के रन डिक्लेअर 35 ही किये जायेंगे ,
                    </p>
                    <p>
                      {" "}
                      10. एडवांस सेशन और फैंसी केवल 1ST इनिंग के लिए ही VALID
                      होती है,{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {/* betting modal */}
      {selectedEventForBet && (
        <Modal
          show={selectedEventForBet}
          centered
          className="bet-modal"
          onClose={() => {
            dispatch({
              type: "gameData/setSelectedEventForBet",
              payload: null,
            });
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="">
            <div className="modal-content">
              <a
                className="close-modal"
                onClick={() => {
                  dispatch({
                    type: "gameData/setSelectedEventForBet",
                    payload: null,
                  });
                }}
              >
                Cancel
              </a>
              <div className="modal-body">
                <div className="container">
                  <div className="bet-strip row">
                    <div className="col-6">
                      <label>Team</label>
                      <p>{selectedEventForBet?.runnerName}</p>
                    </div>
                    <div className="col-3">
                      <label>Rate</label>
                      <p>{selectedEventForBet?.selectedOdd}</p>
                    </div>
                    <div className="col-3">
                      <label>Mode</label>
                      <p>
                        {" "}
                        {selectedEventForBet?.isFancy
                          ? selectedEventForBet.type == "back"
                            ? "YES"
                            : "NO"
                          : selectedEventForBet?.type != "back"
                          ? "Khai"
                          : "Lagai"}
                      </p>
                    </div>
                  </div>
                  <div className="my-3 row">
                    <div className="bet-price-btns col-lg-12 col-md-12 col-sm-12 col-12">
                      <button
                        type="button"
                        className="bet-price-btn btn btn-primary"
                        onClick={() => handleDefaultValueClick(100)}
                      >
                        100
                      </button>
                      <button
                        type="button"
                        className="bet-price-btn btn btn-primary"
                        onClick={() => handleDefaultValueClick(500)}
                      >
                        500
                      </button>
                      <button
                        type="button"
                        className="bet-price-btn btn btn-primary"
                        onClick={() => handleDefaultValueClick(1000)}
                      >
                        1000
                      </button>
                      <button
                        type="button"
                        className="bet-price-btn btn btn-primary"
                        onClick={() => handleDefaultValueClick(2000)}
                      >
                        2000
                      </button>
                      <button
                        type="button"
                        className="bet-price-btn btn btn-primary"
                        onClick={() => handleDefaultValueClick(5000)}
                      >
                        5000
                      </button>
                      <button
                        type="button"
                        className="bet-price-btn btn btn-primary"
                        onClick={() => handleDefaultValueClick(10000)}
                      >
                        10000
                      </button>
                      <button
                        type="button"
                        className="bet-price-btn btn btn-primary"
                        onClick={() => handleDefaultValueClick(20000)}
                      >
                        20000
                      </button>
                      <button
                        type="button"
                        className="bet-price-btn btn btn-primary"
                        onClick={() => handleDefaultValueClick(50000)}
                      >
                        50000
                      </button>
                      <button
                        type="button"
                        className="bet-price-btn btn btn-primary"
                        onClick={() => handleDefaultValueClick(100000)}
                      >
                        100000
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="mb-3 input-group">
                        <input
                          placeholder="Amount"
                          value={selectedEventForBet?.amount}
                          onChange={(e) => {
                            dispatch({
                              type: "gameData/setSelectedEventForBet",
                              payload: {
                                ...selectedEventForBet,
                                amount: e.target.value,
                                profit: getProfit(
                                  e.target.value,
                                  game,
                                  selectedEventForBet
                                ).profit,
                                loss: getProfit(
                                  e.target.value,
                                  game,
                                  selectedEventForBet
                                ).loss,
                              },
                            });
                          }}
                          type="Number"
                          className="form-control"
                        />
                        <span
                          className="bg-danger text-white input-group-text"
                          id="basic-addon3"
                        >
                          <p>{betTimer}</p>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-0 modal-footer">
                <button
                  type="button"
                  className="w-100 m-0 btn btn-primary"
                  onClick={placeBet}
                >
                  Placebet
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
