import React, { useState } from 'react';
import Header from './components/Header';
import { useDispatch, useSelector } from "react-redux";

import { changePasswordAPI } from "../service/AuthService";
import { Encrypt } from "../utils/constants";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

const ChangePassword = () => {
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [oldPassword, setOldPassword] = React.useState("");
  const userData = useSelector((state) => state.account.userData);

  const dispatch = useDispatch();
  const changePassword = async () => {
    if (password === "" || confirmPassword === "" || oldPassword === "") {
      toast.error("All fields are required");
      return;
    }
    if (password !== confirmPassword) {
      toast.error("Password and Confirm Password do not match");
      return;
    }
    if (password === oldPassword) {
      toast.error("New password should not be same as old password");
      return;
    }
    if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        password
      )
    ) {
      toast.error(
        "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special case character"
      );
      return;
    }
    var { data, code, message, response, error } = await changePasswordAPI(
      {
        newPassword: Encrypt(password),
        password: Encrypt(oldPassword),
        resetRequired: false,
        uid: userData.id,
      },
      userData.token
    );
    if (code === 200) {
      toast.success("Password changed successfully");
      dispatch({
        type: "accountData/logOut",
        payload: {},
      });
      dispatch({
        type: "gameData/logOut",
        payload: {},
      });
      localStorage.clear();
      window.location.href = "/";
    } else {
      toast.error(response);
    }
  };
  return (
    <>
      <div className="position-relative">
        <Link className="btn btn-primary w-100 backbutton" to="/dashboard">
          Back to Main Menu
        </Link>
        <div className="toast-container position-absolute top-0 start-50 translate-middle-x p-3 position-fixed mt-5" />
        <div className="mt-4 container">
          <div className="mt-4 row">
            <div className="m-auto col-lg-4 col-md-6 col-sm-7 col-12">
              <div className="p-4 card">
                <h3 className="card-title text-center text-bold">
                  CHANGE PASSWORD
                </h3>
                <form className="">
                  <div className="my-2">
                    <div className="mb-3 input-group">
                      <span className="bg-dark-opacity input-group-text">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path d="M3.433 17.325 3.079 19.8a1 1 0 0 0 1.131 1.131l2.475-.354C7.06 20.524 8 18 8 18s.472.405.665.466c.412.13.813-.274.948-.684L10 16.01s.577.292.786.335c.266.055.524-.109.707-.293a.988.988 0 0 0 .241-.391L12 14.01s.675.187.906.214c.263.03.519-.104.707-.293l1.138-1.137a5.502 5.502 0 0 0 5.581-1.338 5.507 5.507 0 0 0 0-7.778 5.507 5.507 0 0 0-7.778 0 5.5 5.5 0 0 0-1.338 5.581l-7.501 7.5a.994.994 0 0 0-.282.566zM18.504 5.506a2.919 2.919 0 0 1 0 4.122l-4.122-4.122a2.919 2.919 0 0 1 4.122 0z" />
                        </svg>
                      </span>
                      <input
                        onChange={(e) => setOldPassword(e.target.value)}
                        placeholder="Current Password"
                        name=""
                        type="password"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="my-2">
                    <div className="mb-3 input-group">
                      <span className="bg-dark-opacity input-group-text">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path d="M3.433 17.325 3.079 19.8a1 1 0 0 0 1.131 1.131l2.475-.354C7.06 20.524 8 18 8 18s.472.405.665.466c.412.13.813-.274.948-.684L10 16.01s.577.292.786.335c.266.055.524-.109.707-.293a.988.988 0 0 0 .241-.391L12 14.01s.675.187.906.214c.263.03.519-.104.707-.293l1.138-1.137a5.502 5.502 0 0 0 5.581-1.338 5.507 5.507 0 0 0 0-7.778 5.507 5.507 0 0 0-7.778 0 5.5 5.5 0 0 0-1.338 5.581l-7.501 7.5a.994.994 0 0 0-.282.566zM18.504 5.506a2.919 2.919 0 0 1 0 4.122l-4.122-4.122a2.919 2.919 0 0 1 4.122 0z" />
                        </svg>
                      </span>
                      <input
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="New Password"
                        name="password"
                        type="password"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="my-2">
                    <div className="mb-3 input-group">
                      <span className="bg-dark-opacity input-group-text">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path d="M3.433 17.325 3.079 19.8a1 1 0 0 0 1.131 1.131l2.475-.354C7.06 20.524 8 18 8 18s.472.405.665.466c.412.13.813-.274.948-.684L10 16.01s.577.292.786.335c.266.055.524-.109.707-.293a.988.988 0 0 0 .241-.391L12 14.01s.675.187.906.214c.263.03.519-.104.707-.293l1.138-1.137a5.502 5.502 0 0 0 5.581-1.338 5.507 5.507 0 0 0 0-7.778 5.507 5.507 0 0 0-7.778 0 5.5 5.5 0 0 0-1.338 5.581l-7.501 7.5a.994.994 0 0 0-.282.566zM18.504 5.506a2.919 2.919 0 0 1 0 4.122l-4.122-4.122a2.919 2.919 0 0 1 4.122 0z" />
                        </svg>
                      </span>
                      <input
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Confirm Password"
                        name="confirm_password"
                        type="password"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <button
                    onClick={changePassword}
                    type="button"
                    className="w-100 mt-4 btn btn-success"
                    style={{ backgroundColor: "rgb(0, 45, 91)" }}
                  >
                    CHANGE PASSWORD
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Link className="casino-back-btn backbutton" to="/dashboard">
          Back to Main Menu
        </Link>
      </div>
    </>
  );
};

export default ChangePassword;
