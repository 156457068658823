import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import { Link } from "react-router-dom";
import {
  getAccountStatementAPI,
  getBetsFromAccountLogs,
  getCoinHistoryAPI,
} from "../service/AuthService";
import moment from "moment";
import { useDispatch } from "react-redux";
import { searchSubstringInArrayObjects } from "../utils/constants";
export default function Statement() {
  const [activeTab, setActiveTab] = useState("CRICKET");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const [data, setData] = React.useState([]);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [currentPageData, setCurrentPageData] = React.useState([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [type, setType] = React.useState("ALL");
  const dispatch = useDispatch();
  const [startDate, setStartDate] = React.useState(
    moment()
      .subtract(7, "days")
      .format("YYYY-MM-DD")
  );
  const [noOfPages, setNoOfPages] = React.useState(0);
  const [endDate, setEndDate] = React.useState(moment().format("YYYY-MM-DD"));
  const [selectedType, setSelectedType] = React.useState("ALL");
  const [logData, setLogData] = React.useState([]);
  const [selectedLogId, setSelectedLogId] = React.useState(null);
  const isMobileView = window.innerWidth < 768;


  const getAccountStatement = async () => {
    let payload = {
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      type: type,
    };
    const {
      response: { coinHistory },
    } = await getCoinHistoryAPI(payload);
    setNoOfPages(Math.ceil(coinHistory.length / rowsPerPage));
    setCurrentPageData(coinHistory.slice(0, rowsPerPage));
    setData(coinHistory);
  };
  const search = async (e) => {
    if (e.target.value == "") return getAccountStatement();
    setData(
      searchSubstringInArrayObjects(
        data,
        ["comment", "debitInfo", "creditInfo", "updatedBalance"],
        e.target.value
      )
    );
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    getAccountStatement();
  };
  useEffect(() => {
    getAccountStatement();
  }, [type, startDate, endDate]);
  useEffect(() => {
    setCurrentPageData(
      data.slice(
        pageNumber * rowsPerPage,
        pageNumber * rowsPerPage + rowsPerPage
      )
    );
  }, [pageNumber, selectedType]);

  return (
    <>
      <div className="position-relative">
        <Link className="btn btn-primary backbutton w-100" to="/dashboard">
          Back to Main Menu
        </Link>
        <div className="statement-tabs">
          <button
            type="button"
            className={`btn btn-primary ${
              activeTab === "CRICKET"
                ? "bg-primary text-white active"
                : "bg-white text-primary"
            }`}
            onClick={() => handleTabClick("CRICKET")}
          >
            CRICKET
          </button>
          <button
            type="button"
            className={`btn btn-primary ${
              activeTab === "CASINO"
                ? "bg-primary text-white active"
                : "bg-white text-primary"
            }`}
            onClick={() => handleTabClick("CASINO")}
          >
            CASINO
          </button>
        </div>
        <div className="container-fluid p-0">
          <main className="warp">
            {activeTab === "CRICKET" && (
              <div>
                <table className="table-bordered text-center w-100 my-2">
                  <thead
                    height="35"
                    bgcolor="#002D5B"
                    className="FontTextWhite10px cell-td pdr-4"
                  >
                    <tr>
                      <th className="ldg-tbl-th match-box-color">DATE</th>

                      <th className="ldg-tbl-th match-box-color">DEBIT</th>
                      <th className="ldg-tbl-th match-box-color">CREDIT</th>
                      <th className="ldg-tbl-th match-box-color">BALANCE</th>
                      <th className="ldg-tbl-th match-box-color">REMARK</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentPageData.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className="ldg-tbl-td">
                            {moment(item.createdDate).format("DD-MM-YYYY")}
                          </td>
                          <td className="ldg-tbl-td">{item.debit}</td>
                          <td className="ldg-tbl-td">{item.credit}</td>
                          <td className="ldg-tbl-td">
                            {item.updatedBalance}
                          </td>
                          <td className="ldg-tbl-td">{item.description}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
            {activeTab === "CASINO" && (
              <div>
                <h5 className="mt-3 text-center">CASINO</h5>
              </div>
            )}
            <ul className="pagination justify-content-center mt-2 snipcss-ZlA51">
              <li
                onClick={() => {
                  if (pageNumber > 0) {
                    setPageNumber(0);
                  }
                }}
                className="page-item "
              >
                <a tabIndex={-1} className="page-link">
                  &lt;&lt;
                </a>
              </li>
              <li
                onClick={() => {
                  if (pageNumber > 0) {
                    setPageNumber(pageNumber - 1);
                  }
                }}
                className="page-item "
              >
                <a tabIndex={-1} className="page-link">
                  &lt;
                </a>
              </li>
              {Array.from({ length: noOfPages }, (_, i) => i + 1).map(
                (item, index) => (
                  <li
                    className={`page-item ${
                      index == pageNumber ? "active" : ""
                    }`}
                  >
                    <a tabIndex={0} className="page-link">
                      {index + 1}
                    </a>
                  </li>
                )
              )}
              <li
                onClick={() => {
                  if (pageNumber < noOfPages - 1) {
                    setPageNumber(pageNumber + 1);
                  }
                }}
                className="page-item "
              >
                <a tabIndex={-1} className="page-link">
                  &gt;
                </a>
              </li>
              <li
                onClick={() => {
                  if (pageNumber < noOfPages - 1) {
                    setPageNumber(noOfPages - 1);
                  }
                }}
                className="page-item "
              >
                <a tabIndex={-1} className="page-link">
                  &gt;&gt;
                </a>
              </li>
            </ul>
          </main>
        </div>
      </div>
    </>
  );
}
