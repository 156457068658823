import React from "react";
import { useEffect } from "react";
import moment from "moment";

import { Link } from "react-router-dom";

import { getCasinoBetReportAPI } from "../../service/AuthService";
import { CASINOS } from "../../utils/constants";

export default function CasinoMyBets() {
  const [data, setData] = React.useState([]);

  const [loading, setLoading] = React.useState(false);

  const [selectedType, setSelectedType] = React.useState("ALL");

  const getAccountStatement = async () => {
    setLoading(true);

    const { response } = await getCasinoBetReportAPI();

    if (selectedType == "ALL") {
      setData(response);
    } else {
      setData(
        response.filter(
          (item) => item?.casino?.toLowerCase() === selectedType?.toLowerCase()
        )
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    getAccountStatement();
  }, [selectedType]);
  useEffect(() => {}, [selectedType]);
  return (
    <div className="position-relative">
      <Link className="btn btn-primary backbutton w-100" to="/dashboard">
        Back to Main Menu
      </Link>
      {loading ? (
        <>
          <div className="loader">
            <img
              src={require("../../assets/logo2.png")}
              alt="Loader"
              class="flip-vertical-right"
            />
          </div>
        </>
      ) : null}
      <main className="warp table-responsive ">
        <div align="center" className="menu">
          <select style={{ width: "90%", margin: 5, padding: 5 }}>
            <option value="ALL">ALL</option>
            {CASINOS.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </select>
        </div>
        <table className="table-bordered text-center w-100 my-2 mt-0">
          <tbody>
            <tr
              style={{
                backgroundColor: " rgb(0, 45, 91)",
                color: "white",
                fontWeight: "bold",
                fontSize: "15px",
              }}
            >
              <td colspan="12" style={{ fontSize: "18px", padding: "10px" }}>
                CASINO BETS
              </td>
            </tr>
            <tr>
              <td
                width="15%"
                align="center"
                valign="middle"
                bgcolor="#002D5B"
                className="FontTextWhite10px cell-td pdr-4"
              >
                CASINO
              </td>
              <td
                width="15%"
                align="center"
                valign="middle"
                bgcolor="#002D5B"
                className="FontTextWhite10px cell-td"
              >
                RUNNER NAME
              </td>
              <td
                width="15%"
                align="center"
                valign="middle"
                bgcolor="#002D5B"
                className="FontTextWhite10px cell-td"
              >
                AMOUNT
              </td>
              <td
                width="15%"
                align="center"
                valign="middle"
                bgcolor="#002D5B"
                className="FontTextWhite10px cell-td"
              >
                RATE
              </td>

              <td
                width="15%"
                align="center"
                valign="middle"
                bgcolor="#002D5B"
                className="FontTextWhite10px cell-td"
              >
                PROFIT/LOSS
              </td>
              <td
                width="15%"
                align="center"
                valign="middle"
                bgcolor="#002D5B"
                className="FontTextWhite10px cell-td"
              >
                WONBY
              </td>
              <td
                width="15%"
                align="center"
                valign="middle"
                bgcolor="#002D5B"
                className="FontTextWhite10px cell-td"
              >
                DATE
              </td>
            </tr>
            {data
              ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .map((item) => (
                <tr>
                  <td>{item?.casino}</td>
                  <td>{item?.runnerName}</td>
                  <td>{item?.amount}</td>
                  <td>{item?.backRate}</td>

                  <td>{item?.profitLoss}</td>
                  <td>{item?.wonBy}</td>
                  <td>
                    {moment(item?.createdAt + "Z").format("DD/MM/YYYY hh:mm A")}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </main>
    </div>
  );
}
