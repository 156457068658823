import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllMatchesOddsCricketAPI,
  getMatchDetailsCricketAPI,
} from "../service/cricket";

export default function Rules() {
  const [language, setLanguage] = useState("hindi");
  const toggleLanguage = (newLanguage) => {
    setLanguage(newLanguage);
  };
  const dispatch = useDispatch();
  const [gameSettings, setGameSettings] = useState({});
  const data = useSelector((state) => state.gameData["cricketGames"]);
  const userData = useSelector((state) => state.account?.userData);

  const getCricketMatches = async () => {
    try {
      const { response, code } = await getAllMatchesOddsCricketAPI();
      let response_ = response.filter((item) => item?.inPlay);

      for (let i = 0; i < response_.length; i++) {
        let matchData = await getCricketMatchDetails(response_[i]?.cricketId);
        response_[i].matchData = matchData;
      }
      
      if (code == 200) {
        dispatch({
          type: "gameData/setCricketGames",
          payload: response_,
        });
      }
    } catch (e) {}
  };
  const getCricketMatchDetails = async (id) => {
    const { response } = await getMatchDetailsCricketAPI(id);
    return response;
  };

  useEffect(() => {
    let interval;

    getCricketMatches();
    interval = setInterval(() => {
      getCricketMatches();
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  
  return language == "english" ? (
    <div className="position-relative">
      <div className="d-flex m-auto" style={{ width: 300 }}>
        <button
          type="button"
          onClick={() => setLanguage("english")}
          className="w-100 tab-button btn btn-primary"
          style={{ backgroundColor: "rgb(255, 145, 31)" }}
        >
          English
        </button>
        <button
          onClick={() => setLanguage("hindi")}
          type="button"
          className="w-100 tab-button btn btn-primary"
          style={{ backgroundColor: "rgb(132, 179, 50)" }}
        >
          Hindi
        </button>
      </div>
      <div className="mt-4 container">
        <ol className="rules-list">
          <li>
            Please give a few minutes to understand rules of SIXPRO here, as
            best as you can.
          </li>
          <li>Change your password after you log in.</li>
          <li>All the advance bets will be accepted after the toss.</li>
          <li>
            If game is cancelled or tie then all the deals will be cancelled
            and the transactions will be done on session and fancy which are
            completed.
          </li>
          <li>
            TEST MATCH ME , PARTNERSHIP OR PLAYERS RUN ,KHARAB MAUSHAM AUR
            MATCH ABANDONED (DRAW) HOTA HAI TO RESULT US TIME JO SCORE HOGA US
            HISAB SE DECLARE HOGA.
          </li>
          <li>Following Package Limits are applied for Matches:</li>
          <li>
            During the match, please bet only after confirming the deal. Once
            the deal is confirmed, it cannot be changed or removed.
            Responsibility of every deal is yours.
          </li>
          <li>All transactions will be validated from ledger only.</li>
          <li>
            It'll be user's responsibility for internet connection problem.
          </li>
          <li>
            If the match will not take place within 48 hours of the original
            kick-off time bets will be void..
          </li>
          <li>
            Tournament Total Goals, Team Total Goals FT: -. scored in 90
            minutes or extra time will count.Goals scored in penalty shootouts
            do not count.
          </li>
          <li>rule12</li>
          <li>rule13</li>
          <li>rule14</li>
          <li>rule15</li>
          <li>rule16</li>
        </ol>
        <p>
          Note: If some kind of breakdown occurs in server or website, only
          successful bets will be accepted. In such situation, any kind of
          debate will be invalid.
        </p>
        <div className="d-flex justify-content-center align-items-center">
          <button
            type="button"
            onClick={() => {
              window.location.href = "/";
            }}
            className="d-flex m-auto btn btn-danger"
            style={{ backgroundColor: "rgb(0, 45, 91)" }}
          >
            Main Menu
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div className="position-relative">
      <div className="d-flex m-auto" style={{ width: 300 }}>
        <button
          type="button"
          onClick={() => setLanguage("english")}
          className="w-100 tab-button btn btn-primary"
          style={{ backgroundColor: "rgb(255, 145, 31)" }}
        >
          English
        </button>
        <button
          onClick={() => setLanguage("hindi")}
          type="button"
          className="w-100 tab-button btn btn-primary"
          style={{ backgroundColor: "rgb(132, 179, 50)" }}
        >
          Hindi
        </button>
      </div>
      <div className="mt-4 container">
        <ol className="rules-list">
          <li
            style={{
              fontSize: "1.5rem",
            }}
          >
            लोगिन करने के बाद अपना पासवर्ड बदल लें।
          </li>
          <li>
            गेम रद्द होने या टाई होने पर मैच के सभी सौदे रद्द माने जायेंगे और
            जो सेशन पुरे हो चुके हे, उनके हिसाब से ही COINS कम या ज्यादा
            होंगे।
          </li>
          <li>
            मैच के दौरान भाव को देख व समझ के ही सौदा करे। किये गए किसी भी सौदे
            को हटाया या बदला नहीं जाएगा। सभी सौदे के लिए स्वयं आप ही जिम्मेदार
            होंगे।
          </li>
          <li>
            मैच या सेशन भाव गलत चलने पर जो भी मैच या सेशन के सौदे हुए हे वह
            स्वतः हट जायेंगे।
          </li>
          <li>
            मैच में जो सेशन कम्पलीट होंगे सिर्फ उनके हिसाब से कॉइन कम या
            ज्यादा होंगे और जो सेशन कम्पलीट नहीं हुए है बो सारे सेशन रद्द हो
            जाएंगे|
          </li>
          <li>
            अगर कोई सेशन रनिंग मै चल रहा है और टीम जीत जाती है या आलआउट हो
            जाती है तो सेशन डिक्लेअर होगा।
          </li>
          <li>मैच मैं ओवर कम होने पर एडवांस सेसन फैंसी कैंसल हो जाएंगी|</li>
          <li>
            मैच में ओवर कम होने पर अगर सेम टीम दुबारा खेलने के लिए आती है तो
            जो रनिंग में प्लेयर के रन और पार्टनरशीप डिक्लेयर होगी। अगर ओवर कम
            होने पर दूसरी टीम खेलने के लिए आती है तो जो रनिंग में प्लेयर रन,
            पार्टनरशीप रद्द हो जाएंगे
          </li>
          <li>
            प्लेयर के रिटायर्ड हर्ट या इंजर्ड होने पर प्लेयर के रन डिक्लेअर
            किये जायेंगे|
          </li>
          <li>
            सेशन को डिक्लेअर और कैंसिल करने के लिए कंपनी का निर्णय अन्तिम
            होगा| ऐसी स्थिति में किसी भी तरह का वाद-विवाद मान्य नहीं होगा|
          </li>
          <li>
            टेस्ट में पारी डिक्लेअर होने पर जो सेशन रनिंग में हे उस सेशन को
            डिक्लेअर करने के लिए दूसरी टीम के ओवर या बॉल काउंट किये जायेंगे|
          </li>
          <li>
            अगर कैसिनो मैं किसी कारन से डिसिशन नहीं आता है तो उस बेट के कोइन्स
            वापिस कर दिए जायेगे इसमें कोई बाद बिबाद मान्य नहीं होगा|
          </li>
          <li>
            यदि मैच किक ऑफ होने के बाद बारिश या किसी और कारन से रुकता है और 48
            घंटे तक चालू नहीं होता है तो टीम के सभी सौदे रद्द मने जायेंगे |
          </li>
          <li>
            टूर्नामेंट टोटल गोल्स, टीम टोटल गोल्स FT:-. 90 मिनट में किए गए गोल
            या अतिरिक्त समय को गिना जाएगा। पेनल्टी शूटआउट में किए गए गोलों की
            गिनती नहीं की जाती है|
          </li>
          <li>मैच ABANDED होने पैर टीम के सभी सौदे रद्द कर दिए जायेगे।|</li>
          <li>
            यदि डिसक्वॉलिफिक्शन और रेटिरमेंट तक 1st सेट कम्पलीट नहीं होता है
            तो मैच के सभी सौदे रद्द मने जायेगे|
          </li>
        </ol>
        <p>
          नोट : सर्वर या वेबसाईट में किसी तरह की खराबी आने या बंद हो जाने पर
          केवल किये गए सौदे ही मान्य होंगे। ऐसी स्थिति में किसी भी तरह का
          वाद-विवाद मान्य नहीं होगा।
        </p>
        <div className="d-flex justify-content-center align-items-center">
          <button
            type="button"
            onClick={() => (window.location.href = "/")}
            className="d-flex m-auto btn btn-danger"
            style={{ backgroundColor: "rgb(0, 45, 91)" }}
          >
            Main Menu
          </button>
        </div>
      </div>
    </div>
  );
}
