import React from "react";
import { useEffect } from "react";
import moment from "moment";

import { getAccountStatementAPI } from "../service/AuthService";
import { Link, useNavigate } from "react-router-dom";

export default function Ledger() {
  const [data, setData] = React.useState([]);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [currentPageData, setCurrentPageData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [type, setType] = React.useState("ALL");
  const navigate = useNavigate();

  const [startDate, setStartDate] = React.useState(
    moment()
      .subtract(7, "days")
      .format("YYYY-MM-DD")
  );
  const [noOfPages, setNoOfPages] = React.useState(0);
  const [endDate, setEndDate] = React.useState(moment().format("YYYY-MM-DD"));
  const [selectedType, setSelectedType] = React.useState("ALL");

  const getAccountStatement = async () => {
    setLoading(true);
    let payload = {
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      type: type,
    };
    const { response } = await getAccountStatementAPI(payload);
    setNoOfPages(Math.ceil(response.length / rowsPerPage));
    setCurrentPageData(response.slice(0, rowsPerPage));
    setData(response);
    setLoading(false);
  };

  useEffect(() => {
    getAccountStatement();
  }, [type, startDate, endDate]);
  useEffect(() => {
    setCurrentPageData(
      data.slice(
        pageNumber * rowsPerPage,
        pageNumber * rowsPerPage + rowsPerPage
      )
    );
  }, [pageNumber, selectedType]);
  return (
    <div className="position-relative">
      <Link className="btn btn-primary backbutton w-100" to="/dashboard">
        Back to Main Menu
      </Link>
      {loading ? (
        <>
          <div className="loader">
            <img
              src={require("../assets/logo2.png")}
              alt="Loader"
              class="flip-vertical-right"
            />
          </div>
        </>
      ) : null}
      <main className="warp table-responsive statement-tables">
        <table className="table-bordered text-center w-100 my-2 mt-0">
          <tbody>
            <tr
              style={{
                backgroundColor: " rgb(0, 45, 91)",
                color: "white",
                fontWeight: "bold",
                fontSize: "15px",
              }}
            >
              <td colspan="5" style={{ fontSize: "18px", padding: "10px" }}>
                MY LEDGER
              </td>
            </tr>
            <tr>
              <td
                width="40%"
                height="35"
                align="center"
                valign="middle"
                bgcolor="#002D5B"
                className="FontTextWhite10px cell-td pdr-4"
              >
                MATCH NAME
              </td>
              <td
                width="15%"
                align="center"
                valign="middle"
                bgcolor="#002D5B"
                className="FontTextWhite10px cell-td pdr-4"
              >
                Won By
              </td>
              <td
                width="15%"
                align="center"
                valign="middle"
                bgcolor="#002D5B"
                className="FontTextWhite10px cell-td"
              >
                Won
              </td>
              <td
                width="15%"
                align="center"
                valign="middle"
                bgcolor="#002D5B"
                className="FontTextWhite10px cell-td"
              >
                LOST
              </td>
              <td
                width="15%"
                align="center"
                valign="middle"
                bgcolor="#002D5B"
                className="FontTextWhite10px cell-td"
              >
                Balance
              </td>
            </tr>
            {data
              ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .map((item) => (
                <tr>
                  <td
                    style={{
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      item?.sport == "CRICKET"
                        ? navigate(`/complete-game/${item?.accountLogId}`, {
                            state: {
                              item,
                            },
                          })
                        : navigate(`/casino/casino-bets`)
                    }
                  >
                    {item?.comment}
                  </td>
                  <td>{item?.wonBy}</td>
                  <td>{item?.creditInfo}</td>
                  <td>{item?.debitInfo}</td>
                  <td>{item?.updatedBalance}</td>
                </tr>
              ))}
          </tbody>
        </table>
        <ul className="pagination justify-content-center mt-2 snipcss-ZlA51">
          <li
            onClick={() => {
              if (pageNumber > 0) {
                setPageNumber(0);
              }
            }}
            className="page-item "
          >
            <a tabIndex={-1} className="page-link">
              &lt;&lt;
            </a>
          </li>
          <li
            onClick={() => {
              if (pageNumber > 0) {
                setPageNumber(pageNumber - 1);
              }
            }}
            className="page-item "
          >
            <a tabIndex={-1} className="page-link">
              &lt;
            </a>
          </li>
          {Array.from({ length: noOfPages }, (_, i) => i + 1).map(
            (item, index) => (
              <li
                className={`page-item ${index == pageNumber ? "active" : ""}`}
              >
                <a tabIndex={0} className="page-link">
                  {index + 1}
                </a>
              </li>
            )
          )}
          <li
            onClick={() => {
              if (pageNumber < noOfPages - 1) {
                setPageNumber(pageNumber + 1);
              }
            }}
            className="page-item "
          >
            <a tabIndex={-1} className="page-link">
              &gt;
            </a>
          </li>
          <li
            onClick={() => {
              if (pageNumber < noOfPages - 1) {
                setPageNumber(noOfPages - 1);
              }
            }}
            className="page-item "
          >
            <a tabIndex={-1} className="page-link">
              &gt;&gt;
            </a>
          </li>
        </ul>
      </main>
    </div>
  );
}
