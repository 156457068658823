import React from "react";
import "./sevenupdown.scoped.css";
export default function SevenUpDown() {
  return (
    <div id="tp-bg" className="tp-bg">
      <div id="game" data-match-id="" data-provider-id="" />
      {/* ******************ROULETTE MODAL CODE START**************** */}
      <div style={{ display: "none" }} id="gameModal" className="modalHistory">
        <div className="history-modal-content">
          <span className="close-history-modal">
            <i className="fa fa-window-close" aria-hidden="true" />
          </span>
          <div className="modal-body">
            <div className="modal-container">
              <div className="btn-sec flex">
                <div className="game-btn btn flex-1-game-history c-align checked modal-section">
                  <label className="centered-button" htmlFor="">
                    <button data-button-id="game-history">GAME HISTORY</button>
                  </label>
                </div>
                <div className="rules-btn btn flex-1-game-history modal-section">
                  <label htmlFor="" className="rules-label">
                    {" "}
                    <button data-button-id="game-rules">RULES</button>{" "}
                  </label>
                </div>
              </div>
              <div className="modal-content-sec">
                <div id="matkaRules" />
                <div id="hindiMatkaRules" />
                <table
                  className="g-history-sec sec game-history-table"
                  id="gameHistoryTable"
                >
                  <thead>
                    <tr style={{}}>
                      <th>S.NO</th>
                      <th>Match ID</th>
                      <th>Winner</th>
                      <th>PLAY</th>
                      <th>WIN</th>
                    </tr>
                  </thead>
                  <tbody />
                </table>
                <div className="rules-sec sec hide ">
                  <p>
                    <span>● Welcome to 7Up-7Down game.</span>
                  </p>
                  <p>
                    <span>● There are total 13 cards in the game; A to K.</span>
                  </p>
                  <p>
                    <span>● The ranking of cards is as follows:</span>
                  </p>
                  <p>
                    <span>
                      &nbsp;&nbsp;&nbsp;&nbsp; Up (High Card) - 8 | 9 | 10 | J |
                      Q | K
                    </span>
                  </p>
                  <p>
                    <span>
                      &nbsp;&nbsp;&nbsp;&nbsp; Down (Low Card) - A | 2 | 3 | 4 |
                      5 | 6
                    </span>
                  </p>
                  <p>
                    <span>● In the 7Up-7Down game one hand is dealt.</span>
                  </p>
                  <p>
                    <span>
                      ● The card dealt will be the winning hand of the game.
                    </span>
                  </p>
                  <p>
                    <span>● If the card dealt is from A to 6: Low wins.</span>
                  </p>
                  <p>
                    <span>● If the card dealt is from 8 to K: High wins.</span>
                  </p>
                  <p>
                    <span>
                      ● All the player who have placed bet on the winning hand
                      wins the game.
                    </span>
                  </p>
                  <p>
                    <span>
                      ● Payout rate for All Cards Player Market is : 13.0.
                    </span>
                  </p>
                  <p>
                    <span>
                      ● Payout rate for High/Low Player Market is : 2.0.
                    </span>
                  </p>
                  <p>
                    <span>
                      ● Games will be closed 1 hr everyday for maintenance.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ******************ROULETTE MODAL CODE END**************** */}
      <div className="tp-videp-panel">
        <div className="tp-logo tp-width flex aj-cen">
          <img
            alt="7up 7down"
            src="http://sky7777.fun:80/assets/lottery/7up-7down/7up-7down-58c9b68cd4ff257494dac1c87962dc01.png"
          />
          <span className="teen">7UP-7DOWN</span>
        </div>
        <div className="tp-vid-outer tp-width">
          <iframe
            style={{ border: "none", height: "220.571px" }}
            width={700}
            height={400}
            src="https://feedvdo.jaaztech.com/Video/VideoFeed?token=QVbu0naS1M&table=T_7UPDOWN01"
          />
        </div>
        <div className="data-holder last-winners-holder">
          <div className="last-winners flex">
            <div className="flex tp-player p-left f-bs w-100">
              <p className="winner-box-title text-white">
                LAST 10 <br />
                Draws
              </p>
              <div className="flex flex-1 winner-box-holder align-items-center justify-content-between px-2">
                <p className="winner-box draws-l" data-id={2327186}>
                  2
                </p>
                <p className="winner-box draws-l" data-id={2327190}>
                  5
                </p>
                <p className="winner-box draws-h" data-id={2327193}>
                  10
                </p>
                <p className="winner-box draws-h" data-id={2327195}>
                  Q
                </p>
                <p className="winner-box draws-l" data-id={2327198}>
                  5
                </p>
                <p className="winner-box draws-l" data-id={2327201}>
                  3
                </p>
                <p className="winner-box draws-h" data-id={2327203}>
                  9
                </p>
                <p className="winner-box draws-h" data-id={2327207}>
                  9
                </p>
                <p className="winner-box draws-h" data-id={2327209}>
                  J
                </p>
                <p className="winner-box draws-h" data-id={2327213}>
                  J
                </p>
              </div>
            </div>
          </div>
          {/* Modal */}
          <div
            className="modal fade"
            id="showDrawCardsModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="showDrawCardsModalTitle"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="showDrawCardsModalTitle">
                    7Up-7Down Result
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="round-id">
                    <p>
                      <b>Match ID :</b>
                      <span id="heroicMatchId" />
                    </p>
                  </div>
                  <div className="player-result playingCards fourColours faceImages simpleCards inText rotateHand">
                    <div style={{ border: "none" }} className="player-a-cards">
                      <ul className="card-a">
                        <li id="seven-up-down-card1">
                          <a className="card" href="#">
                            <span className="rank" />
                            <span className="suit" />
                          </a>
                        </li>
                      </ul>
                      <button className="winner-btn hide player-a-winner">
                        Winner
                      </button>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <p>
                    <b>Hand ID :</b>
                    <span id="videoProviderGameID" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="data-holder d-flex justify-content-center mob-coin-section f-bs pb-4 pt-4 mx-2 mx-lg-0">
          <div className="mob-coin-inner p-2">
            COINS : <span className="available_coins text-white">1407.0</span>
          </div>
        </div>
        <div className="d-flex bets-outer-section my-2 mx-2 mx-lg-0">
          <div className="d-flex w-100 d-flex align-items-center justify-content-between">
            <div className="bet-outer-row-sec">High/Low Card</div>
            <div className="lh-bet-arrow bet-arrow">
              <i className="fa fa-angle-up" style={{ fontSize: 24 }} />
            </div>
          </div>
          <div className="text-center flex-1 bg-grey-light lh-outer br-l-5">
            <div className="text-center flex-1">
              <div
                className="card-btn lh_card_market low_card disabled"
                data-runner_name="Low Card"
                data-runner_id=""
                data-market_id=""
                data-odds_val=""
                data-odd_type="LAGAI"
                data-match_id=""
                data-heroic_market_type=""
              >
                Low Card
              </div>
              <div className="text-white mt-1 card-btm-score lw-card-bet-coins">
                0
              </div>
            </div>
            <div className="card-middle text-center">
              <img
                alt="Card white 7"
                src="http://sky7777.fun:80/assets/lottery/inside_outside/cards/card-white-7-4c2ce9e29421af5ea35cb3c8bcad1be8.png"
              />
            </div>
            <div className="text-center flex-1">
              <div
                className="card-btn lh_card_market high_card disabled"
                data-runner_name="High Card"
                data-runner_id=""
                data-market_id=""
                data-odds_val=""
                data-odd_type="LAGAI"
                data-match_id=""
                data-heroic_market_type=""
              >
                High Card
              </div>
              <div className="text-white mt-1 card-btm-score hg-card-bet-coins">
                0
              </div>
            </div>
          </div>
        </div>
        <div className="bets-outer-section">
          <div className="d-flex w-100 d-flex align-items-center justify-content-between">
            <div className="bet-outer-row-sec">High/Low Card</div>
            <div className="cards-bet-arrow bet-arrow">
              <i className="fa fa-angle-up" style={{ fontSize: 24 }} />
            </div>
          </div>
          <div className="bet-card-outer-sec">
            <div className="d-flex bet-card-outer bg-grey-light mx-2 mx-lg-0 mt-0">
              <div
                className="card-section all_card_market all_card_a disabled"
                data-runner_name="A"
                data-runner_id=""
                data-market_id=""
                data-odds_val=""
                data-odd_type="LAGAI"
                data-match_id=""
                data-heroic_market_type=""
              >
                <img
                  alt="Card white a"
                  src="http://sky7777.fun:80/assets/lottery/inside_outside/cards/card-white-a-ca6f9dc8fe398959081862b07f890c82.png"
                />
                <div className="text-white mt-1 card-btm-score bet-coins">
                  0
                </div>
              </div>
              <div
                className="card-section all_card_market all_card_2 disabled"
                data-runner_name={2}
                data-runner_id=""
                data-market_id=""
                data-odds_val=""
                data-odd_type="LAGAI"
                data-match_id=""
                data-heroic_market_type=""
              >
                <img
                  alt="Card white 2"
                  src="http://sky7777.fun:80/assets/lottery/inside_outside/cards/card-white-2-d3b2ad0d2f08aa2f1afd4cc7bdbcf0c0.png"
                />
                <div className="text-white mt-1 card-btm-score bet-coins">
                  0
                </div>
              </div>
              <div
                className="card-section all_card_market all_card_3 disabled"
                data-runner_name={3}
                data-runner_id=""
                data-market_id=""
                data-odds_val=""
                data-odd_type="LAGAI"
                data-match_id=""
                data-heroic_market_type=""
              >
                <img
                  alt="Card white 3"
                  src="http://sky7777.fun:80/assets/lottery/inside_outside/cards/card-white-3-041d7063e91e3e5bdecf4353ce9f155e.png"
                />
                <div className="text-white mt-1 card-btm-score bet-coins">
                  0
                </div>
              </div>
              <div
                className="card-section all_card_market all_card_4 disabled"
                data-runner_name={4}
                data-runner_id=""
                data-market_id=""
                data-odds_val=""
                data-odd_type="LAGAI"
                data-match_id=""
                data-heroic_market_type=""
              >
                <img
                  alt="Card white 4"
                  src="http://sky7777.fun:80/assets/lottery/inside_outside/cards/card-white-4-d4de5c1cc99ab41731b1eb077cd0145a.png"
                />
                <div className="text-white mt-1 card-btm-score bet-coins">
                  0
                </div>
              </div>
              <div
                className="card-section all_card_market all_card_5 disabled"
                data-runner_name={5}
                data-runner_id=""
                data-market_id=""
                data-odds_val=""
                data-odd_type="LAGAI"
                data-match_id=""
                data-heroic_market_type=""
              >
                <img
                  alt="Card white 5"
                  src="http://sky7777.fun:80/assets/lottery/inside_outside/cards/card-white-5-941cea2775f3fed869227a7604969a9e.png"
                />
                <div className="text-white mt-1 card-btm-score bet-coins">
                  0
                </div>
              </div>
              <div
                className="card-section all_card_market all_card_6 disabled"
                data-runner_name={6}
                data-runner_id=""
                data-market_id=""
                data-odds_val=""
                data-odd_type="LAGAI"
                data-match_id=""
                data-heroic_market_type=""
              >
                <img
                  alt="Card white 6"
                  src="http://sky7777.fun:80/assets/lottery/inside_outside/cards/card-white-6-8ec4ce437637db7c10efd1e0054d807a.png"
                />
                <div className="text-white mt-1 card-btm-score bet-coins">
                  0
                </div>
              </div>
              <div
                className="card-section all_card_market all_card_7 disabled"
                data-runner_name={7}
                data-runner_id=""
                data-market_id=""
                data-odds_val=""
                data-odd_type="LAGAI"
                data-match_id=""
                data-heroic_market_type=""
              >
                <img
                  alt="Card white 7"
                  src="http://sky7777.fun:80/assets/lottery/inside_outside/cards/card-white-7-4c2ce9e29421af5ea35cb3c8bcad1be8.png"
                />
                <div className="text-white mt-1 card-btm-score bet-coins">
                  0
                </div>
              </div>
              <div
                className="card-section all_card_market all_card_8 disabled"
                data-runner_name={8}
                data-runner_id=""
                data-market_id=""
                data-odds_val=""
                data-odd_type="LAGAI"
                data-match_id=""
                data-heroic_market_type=""
              >
                <img
                  alt="Card white 8"
                  src="http://sky7777.fun:80/assets/lottery/inside_outside/cards/card-white-8-32c2f17aa60b14e7018db97420e8731d.png"
                />
                <div className="text-white mt-1 card-btm-score bet-coins">
                  0
                </div>
              </div>
              <div
                className="card-section all_card_market all_card_9 disabled"
                data-runner_name={9}
                data-runner_id=""
                data-market_id=""
                data-odds_val=""
                data-odd_type="LAGAI"
                data-match_id=""
                data-heroic_market_type=""
              >
                <img
                  alt="Card white 9"
                  src="http://sky7777.fun:80/assets/lottery/inside_outside/cards/card-white-9-865f8a3e82df6aaf849054bf965f2b2a.png"
                />
                <div className="text-white mt-1 card-btm-score bet-coins">
                  0
                </div>
              </div>
              <div
                className="card-section all_card_market all_card_10 disabled"
                data-runner_name={10}
                data-runner_id=""
                data-market_id=""
                data-odds_val=""
                data-odd_type="LAGAI"
                data-match_id=""
                data-heroic_market_type=""
              >
                <img
                  alt="Card white 10"
                  src="http://sky7777.fun:80/assets/lottery/inside_outside/cards/card-white-10-b90fef14b7768e7e9fe3848227e353dc.png"
                />
                <div className="text-white mt-1 card-btm-score bet-coins">
                  0
                </div>
              </div>
              <div
                className="card-section all_card_market all_card_j disabled"
                data-runner_name="J"
                data-runner_id=""
                data-market_id=""
                data-odds_val=""
                data-odd_type="LAGAI"
                data-match_id=""
                data-heroic_market_type=""
              >
                <img
                  alt="Card white j"
                  src="http://sky7777.fun:80/assets/lottery/inside_outside/cards/card-white-j-c62bf5c5954005ed58b0e295b3543734.png"
                />
                <div className="text-white mt-1 card-btm-score bet-coins">
                  0
                </div>
              </div>
              <div
                className="card-section all_card_market all_card_q disabled"
                data-runner_name="Q"
                data-runner_id=""
                data-market_id=""
                data-odds_val=""
                data-odd_type="LAGAI"
                data-match_id=""
                data-heroic_market_type=""
              >
                <img
                  alt="Card white q"
                  src="http://sky7777.fun:80/assets/lottery/inside_outside/cards/card-white-q-1b97afc1512c2b18248a0e4ed64949f0.png"
                />
                <div className="text-white mt-1 card-btm-score bet-coins">
                  0
                </div>
              </div>
              <div
                className="card-section all_card_market all_card_k disabled"
                data-runner_name="K"
                data-runner_id=""
                data-market_id=""
                data-odds_val=""
                data-odd_type="LAGAI"
                data-match_id=""
                data-heroic_market_type=""
              >
                <img
                  alt="Card white k"
                  src="http://sky7777.fun:80/assets/lottery/inside_outside/cards/card-white-k-6081a973dd46a432f3cece7f2ba3fc8c.png"
                />
                <div className="text-white mt-1 card-btm-score bet-coins">
                  0
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex pt-2 pb-lg-4 pt-lg-4 tp-mob-mybets">
          <span className="mob-my-bets">MY BETS</span>
        </div>
      </div>
      <div className="tp-bet-panel-container">
        <div className="tp-bet-panel tp-back-bet">
          <div className="tp-model-outer" id="modal-1">
            <div className="tp-model-inner flex aj-cen">
              <span className="close-modal"> ×</span>
              <span className="message" />
            </div>
          </div>
          <div className="tp-bet-title flex">
            <div className="tp-plc-bet l-text">PLACE BET</div>
            <div className="flex-1 tp-bal r-text">
              COINS : <span className="available_coins">1407.0</span>
            </div>
            <img
              alt="Delete"
              className="tp-bet-close"
              src="http://sky7777.fun:80/assets/lottery/teen_patti/delete-e4bbb4127020959669ed1616332cea82.svg"
            />
          </div>
          {/* Market Bet Amount Form start */}
          <div
            className="market-block teen_patti_form"
            style={{ float: "left" }}
          >
            <input
              className="odds_type"
              type="hidden"
              name="odds_type"
              defaultValue=""
            />
            <input
              className="runner_id"
              type="hidden"
              name="runner_id"
              defaultValue=""
            />
            <input
              className="odds_val"
              type="hidden"
              name="odds_val"
              defaultValue=""
            />
            <input
              className="match_id"
              type="hidden"
              name="match_id"
              defaultValue=""
            />
            <input
              className="market_id"
              type="hidden"
              name="market_id"
              defaultValue=""
            />
            <input
              className="heroic_market_type"
              type="hidden"
              name="heroic_market_type"
              defaultValue=""
            />
          </div>
          <div className="tp-bet-inner">
            <table className="tp-bet-table">
              <tbody>
                <tr className="heading-row">
                  <td aline="left" className="pl-10">
                    Bet For
                  </td>
                  <td align="right">Odds</td>
                </tr>
                <tr>
                  <td aline="left" className="pl-10 player_name" />
                  <td align="right" id="getodd" className="odds" />
                </tr>
              </tbody>
            </table>
            <div className="flex tp-input-outer">
              <input
                type="text"
                className="tp-bet-input"
                id="tp-bet-value"
                name="stack"
                defaultValue=""
                placeholder="Enter Amount"
                readOnly="readonly"
              />
            </div>
            <div className="flex tp-btn-outer bt-out-first">
              <div className="tp-btn flex-1 c-text" onclick="tpSuperValue(100)">
                100
              </div>
              <div className="tp-btn flex-1 c-text" onclick="tpSuperValue(500)">
                500
              </div>
              <div
                className="tp-btn flex-1 c-text"
                onclick="tpSuperValue(1000)"
              >
                1000
              </div>
              <div
                className="tp-btn flex-1 c-text"
                onclick="tpSuperValue(2000)"
              >
                2000
              </div>
              <div
                className="tp-btn flex-1 c-text"
                onclick="tpSuperValue(3000)"
              >
                3000
              </div>
            </div>
            <div className="flex tp-btn-outer bt-out-sec">
              <div
                className="tp-btn flex-1 c-text"
                onclick="tpSuperValue(4000)"
              >
                4000
              </div>
              <div
                className="tp-btn flex-1 c-text"
                onclick="tpSuperValue(5000)"
              >
                5000
              </div>
              <div
                className="tp-btn flex-1 c-text"
                onclick="tpSuperValue(10000)"
              >
                10000
              </div>
              <div
                className="tp-btn flex-1 c-text"
                onclick="tpSuperValue(20000)"
              >
                20000
              </div>
              <div
                className="tp-btn flex-1 c-text"
                onclick="tpSuperValue(25000)"
              >
                25000
              </div>
            </div>
            <div className="tp-num-outer">
              <div className="tp-num-row flex">
                <div className="tp-num" onclick="tpNum(1)">
                  1
                </div>
                <div className="tp-num" onclick="tpNum(2)">
                  2
                </div>
                <div className="tp-num" onclick="tpNum(3)">
                  3
                </div>
              </div>
              <div className="tp-num-row flex">
                <div className="tp-num" onclick="tpNum(4)">
                  4
                </div>
                <div className="tp-num" onclick="tpNum(5)">
                  5
                </div>
                <div className="tp-num" onclick="tpNum(6)">
                  6
                </div>
              </div>
              <div className="tp-num-row flex">
                <div className="tp-num" onclick="tpNum(7)">
                  7
                </div>
                <div className="tp-num" onclick="tpNum(8)">
                  8
                </div>
                <div className="tp-num" onclick="tpNum(9)">
                  9
                </div>
              </div>
              <div className="tp-num-row flex">
                <div className="tp-num tp-reset-icon" onclick="resetValue()">
                  RESET
                </div>
                <div className="tp-num" onclick="tpNum(0)">
                  0
                </div>
                <div
                  className="tp-num tp-delete-icon flex aj-cen"
                  id="clear-number"
                >
                  <img
                    alt="Backspace"
                    src="http://sky7777.fun:80/assets/lottery/teen_patti/backspace-346f03b40bcb55a0e22ee9d2d7ade97d.svg"
                  />
                </div>
              </div>
            </div>
            <div className="flex tp-btn-outer bt-out-third">
              <div
                className="tp-btn tp-reset flex-1 c-text"
                onclick="resetValue()"
              >
                RESET
              </div>
              <div className="tp-btn tp-bet-submit flex-1 c-text buttonload">
                <i className="bet_loader fa fa-circle-o-notch fa-spin hide-loader" />
                SUBMIT
              </div>
            </div>
          </div>
          {/* Market Bet Amount Form End */}
          {/* TP Bet History */}
          <div className="tp-history-outer">
            <div className="tp-mybets flex">My Bets</div>
            <div className="tp-bets-history">
              <table>
                <tbody>
                  <tr>
                    <th align="left">Bet for</th>
                    <th
                      align="center"
                      style={{ textAlign: "center !important" }}
                    >
                      Odds
                    </th>
                    <th align="right" style={{ textAlign: "right !important" }}>
                      Stake
                    </th>
                  </tr>
                </tbody>
                <tbody id="bets_info" />
              </table>
            </div>
          </div>
          {/* TP Bet History End */}
        </div>
        <div
          id="open-Modal"
          data-game-type="card-game"
          data-game-sub-type="card-game-se7enup-se7endown"
          data-casino-table-id={7}
          className="game-history-open"
        >
          History/Rules
        </div>
      </div>
      {/* tp-bet-panel close*/}
    </div>
  );
}
