import React, { useState } from "react";
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modal,
  ModalTitle,
  CloseButton,
} from "react-bootstrap";

export default function TermsAndCondition() {
  const [isModalOpen, setIsModalOpen] = useState(true);

  return (
    <div className="container">
      <Modal
        style={{
          top: "20%",
        }}
        show={isModalOpen}
      >
        <ModalHeader className="bg-theme-dark">
          <ModalTitle
            style={{
              justifyContent: "center",
            }}
          >
            {" "}
            Welcome to 99betexch
          </ModalTitle>
          <CloseButton onClick={() => setIsModalOpen(false)} />
        </ModalHeader>
        <ModalBody>
          <h6>
            अगर कोई सेशन रनिंग मै चल रहा है और टीम जीत जाती है या आलआउट हो
            जाती है तो सेशन डिक्लेअर होगा।
          </h6>
        </ModalBody>
        <ModalFooter
          style={{ justifyContent: "center" }}
          className="bg-theme-dark "
        >
          <ModalTitle>Thanks for Visiting Our Site</ModalTitle>
        </ModalFooter>
      </Modal>
      <div className="content_top">
        <div className="content_top-grid3" />
        <div className="clear" />
      </div>
      <div className="skills">
        <h4
          align="center"
          style={{
            color: "#FFF",
            fontFamily: "Verdana, Geneva, sans-serif",
            fontSize: 18,
            fontWeight: "bold",
          }}
        >
          {" "}
          Terms &amp; Conditions{" "}
        </h4>
      </div>
      <br />
      <div className="drag_drop">
        <h4
          align="left"
          style={{
            color: "#000",
            fontFamily: "Verdana, Geneva, sans-serif",
            fontSize: 14,
            marginTop: 5,
          }}
          className="ng-star-inserted"
        >
          1. लोगिन करने के बाद अपना पासवर्ड बदल लें।
        </h4>
        <h4
          align="left"
          style={{
            color: "#000",
            fontFamily: "Verdana, Geneva, sans-serif",
            fontSize: 14,
            marginTop: 5,
          }}
          className="ng-star-inserted"
        >
          2. प्रत्येक गेम के लिए 100/- Coins चार्ज और टेस्ट गेम में प्रतिदिन
          100/- coins चार्ज रहेगा।
        </h4>
        <h4
          align="left"
          style={{
            color: "#000",
            fontFamily: "Verdana, Geneva, sans-serif",
            fontSize: 14,
            marginTop: 5,
          }}
          className="ng-star-inserted"
        >
          3. गेम रद्द होने या टाई होने पर मैच के सभी सौदे रद्द माने जायेंगे और
          जो सेशन पुरे हो चुके हे, उनके हिसाब से ही Coins कम या ज्यादा होंगे।
        </h4>
        <h4
          align="left"
          style={{
            color: "#000",
            fontFamily: "Verdana, Geneva, sans-serif",
            fontSize: 14,
            marginTop: 5,
          }}
          className="ng-star-inserted"
        >
          4. मैच के दौरान भाव को देख व समझ के ही सौदा करे। किये गए किसी भी
          सौदे को हटाया या बदला नहीं जाएगा। सभी सौदे के लिए स्वयं आप ही
          जिम्मेदार होंगे।
        </h4>
        <h4
          align="left"
          style={{
            color: "#000",
            fontFamily: "Verdana, Geneva, sans-serif",
            fontSize: 14,
            marginTop: 5,
          }}
          className="ng-star-inserted"
        >
          5. मैच या सेशन भाव गलत चलने पर जो भी मैच या सेशन के सौदे हुए हे वह
          स्वतः हट जायेंगे।
        </h4>
        <h4
          align="left"
          style={{
            color: "#000",
            fontFamily: "Verdana, Geneva, sans-serif",
            fontSize: 14,
            marginTop: 5,
          }}
          className="ng-star-inserted"
        >
          6. मैच में जो सेशन कम्पलीट होंगे सिर्फ उनके हिसाब से कॉइन कम या
          ज्यादा होंगे और जो सेशन कम्पलीट नहीं हुए है बो सारे सेशन रद्द हो
          जाएंगे|
        </h4>
        <h4
          align="left"
          style={{
            color: "#000",
            fontFamily: "Verdana, Geneva, sans-serif",
            fontSize: 14,
            marginTop: 5,
          }}
          className="ng-star-inserted"
        >
          7. अगर कोई सेशन रनिंग मै चल रहा है और टीम जीत जाती है या आलआउट हो
          जाती है तो सेशन डिक्लेअर होगा।
        </h4>
        <h4
          align="left"
          style={{
            color: "#000",
            fontFamily: "Verdana, Geneva, sans-serif",
            fontSize: 14,
            marginTop: 5,
          }}
          className="ng-star-inserted"
        >
          8. मैच मैं ओवर कम होने पर एडवांस सेसन फैंसी कैंसल हो जाएंगी|
        </h4>
        <h4
          align="left"
          style={{
            color: "#000",
            fontFamily: "Verdana, Geneva, sans-serif",
            fontSize: 14,
            marginTop: 5,
          }}
          className="ng-star-inserted"
        >
          9. मैच में ओवर कम होने पर अगर सेम टीम दुबारा खेलने के लिए आती है तो
          जो रनिंग में प्लेयर के रन और पार्टनरशीप डिक्लेयर होगी। अगर ओवर कम
          होने पर दूसरी टीम खेलने के लिए आती है तो जो रनिंग में प्लेयर रन,
          पार्टनरशीप रद्द हो जाएंगे
        </h4>
        <h4
          align="left"
          style={{
            color: "#000",
            fontFamily: "Verdana, Geneva, sans-serif",
            fontSize: 14,
            marginTop: 5,
          }}
          className="ng-star-inserted"
        >
          10. प्लेयर के रिटायर्ड हर्ट या इंजर्ड होने पर प्लेयर के रन डिक्लेअर
          किये जायेंगे|
        </h4>
        <h4
          align="left"
          style={{
            color: "#000",
            fontFamily: "Verdana, Geneva, sans-serif",
            fontSize: 14,
            marginTop: 5,
          }}
          className="ng-star-inserted"
        >
          11. सेशन को डिक्लेअर और कैंसिल करने के लिए कंपनी का निर्णय अन्तिम
          होगा| ऐसी स्थिति में किसी भी तरह का वाद-विवाद मान्य नहीं होगा|
        </h4>
        <h4
          align="left"
          style={{
            color: "#000",
            fontFamily: "Verdana, Geneva, sans-serif",
            fontSize: 14,
            marginTop: 5,
          }}
          className="ng-star-inserted"
        >
          12. टेस्ट में पारी डिक्लेअर होने पर जो सेशन रनिंग में हे उस सेशन को
          डिक्लेअर करने के लिए दूसरी टीम के ओवर या बॉल काउंट किये जायेंगे|
        </h4>
        <h4
          align="left"
          style={{
            color: "#000",
            fontFamily: "Verdana, Geneva, sans-serif",
            fontSize: 14,
            marginTop: 5,
          }}
          className="ng-star-inserted"
        >
          13. नोट : सर्वर या वेबसाईट में किसी तरह की खराबी आने या बंद हो जाने
          पर केवल किये गए सौदे ही मान्य होंगे। ऐसी स्थिति में किसी भी तरह का
          वाद-विवाद मान्य नहीं होगा।
        </h4>
        {/**/}
      </div>
      <br />
      <a
        className="btn btn-primary bg-theme-dark"
        style={{ color: "#FFFFFF", width: "100%", marginBottom: 10 }}
        href="/dashboard"
      >
        <div className="skills">
          <h4
            align="center"
            style={{
              color: "#FFF",
              fontFamily: "Verdana, Geneva, sans-serif",
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            {" "}
            Continue{" "}
          </h4>
        </div>
      </a>
    </div>
  );
}
