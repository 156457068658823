import React, { useEffect, useState } from "react";
import { getCompleteGamesAPI } from "../service/AuthService";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

export default function CompleteGames() {
  const [cricketData, setCricketData] = useState([]);
  const [loading, setLoading] = useState(false);
  const getCompleteGames = async () => {
    setLoading(true);
    try {
      const { response, code } = await getCompleteGamesAPI();
      if (code === 200) {
                          setCricketData(response);
                        }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };
  const navigate = useNavigate();
  useEffect(() => {
    getCompleteGames();
  }, []);
  return (
    <div className="position-relative">
      {loading ? (
        <>
          <div className="loader">
            <img
              src={require("../assets/logo2.png")}
              alt="Loader"
              class="flip-vertical-right"
            />
          </div>
        </>
      ) : null}
      <main className="warp ">
        <Link className="btn btn-primary backbutton w-100" to="/dashboard">
          Back to Main Menu
        </Link>
        <div>
          <div>
            {cricketData?.completedGames?.map((gameEvent) => (
              <div
                onClick={() =>
                  navigate(`/complete-game/${gameEvent?.accountLogId}`, {
                    state: {
                      item: gameEvent,
                    },
                  })
                }
                className="old-matches-list "
              >
                <div className="TeamName">
                  <div>{gameEvent?.eventName}</div>
                </div>
                <div className="old-match-details">
                  <div /* onClick={() => navigateToGame(gameEvent)} */>
                    <table
                      width="100%"
                      border="0"
                      cellspacing="0"
                      cellpadding="0"
                    >
                      <tbody>
                        <tr>
                          <td width="1%">&nbsp;</td>
                          <td className="GameList">
                            <table
                              width="99%"
                              border="0"
                              cellspacing="0"
                              cellpadding="0"
                            >
                              <tbody>
                                <tr>
                                  <td className="GameList" align="center">
                                    {moment(gameEvent.eventDateTime).format(
                                      "DD MMM hh:mm A"
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="GameList" align="center">
                                    MATCH BETS :{" "}
                                    <span>
                                      {gameEvent?.matchBetCount
                                        ? gameEvent?.matchBetCount
                                        : 0}
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="GameList" align="center">
                                    Session Bets :{" "}
                                    <span>
                                      {gameEvent?.sessionBetCount
                                        ? gameEvent?.sessionBetCount
                                        : 0}
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="center" className="GameList">
                                    Won By : {gameEvent?.wonBy}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    align="center"
                                    className="GameList text-red ng-star-inserted"
                                  >
                                    {gameEvent?.winLoss >= 0 ? (
                                      <span style={{ color: "#00F" }}>
                                        Win coins : {gameEvent?.winLoss}
                                      </span>
                                    ) : (
                                      <span style={{ color: "#ff2e45" }}>
                                        Loss coins : {gameEvent?.winLoss}
                                      </span>
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td width="1%">&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
}
