import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllMatchesOddsCricketAPI,
  getBetCountAPI,
} from "../service/cricket";
import moment from "moment";

const Matches = ({ isUpcoming }) => {
  const dispatch = useDispatch();
  const [selectedGame, setSelectedGame] = useState("cricket");
  const [loading, setLoading] = useState(false);
  const userData = useSelector((state) => state.account.userData);
  const [cricketMatches, setCricketMatches] = useState([]);
  const [activeTab, setActiveTab] = useState("All"); // State to keep track of active tab
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  const naviagte = useNavigate();
  const getCricketMatches = async () => {
    const { response, code } = await getAllMatchesOddsCricketAPI();
    if (code == 200) {
      let counts = await getBetCount();
      response.map((item) => {
        let countData =
          counts?.betCount[
            Object.keys(counts.betCount).find(
              (count) => count == item?.cricketId
            )
          ];
        item.matchCount = countData?.matchCount;
        item.fancyCount = countData?.fancyCount;
        return item;
      });
      setCricketMatches(response);
    }
  };
  useEffect(() => {
    let interval;
    setLoading(true);
    getCricketMatches().then(() => setLoading(false));

    if (selectedGame == "cricket") {
      interval = setInterval(() => {
        getCricketMatches();
      }, 5000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [selectedGame]);
  const navigateToGame = (gameEvent) => {
    if (!userData?.token) {
      dispatch({
        type: "accountData/setLoginPopSate",
        payload: true,
      });
      return;
    }

    if (selectedGame == "all") {
      naviagte(
        `/game/${gameEvent?.sport}/${gameEvent[gameEvent?.sport + "Id"]}`,
        {
          state: { gameEvent },
        }
      );
      window.location.reload();
    } else {
      naviagte(`/game/${selectedGame}/${gameEvent[selectedGame + "Id"]}`, {
        state: { gameEvent },
      });
      window.location.reload();
    }
  };

  const getBetCount = async () => {
    const { response } = await getBetCountAPI();
    return response;
  };
  return (
    <div className="position-relative">
      {loading ? (
        <>
          <div className="loader">
            <img
              src={require("../assets/logo2.png")}
              alt="Loader"
              class="flip-vertical-right"
            />
          </div>
        </>
      ) : null}
      <main className="warp mt-1">
        <Link className="btn btn-primary backbutton w-100" to="/dashboard">
          Back to Main Menu
        </Link>
        <div>
          {!isUpcoming
            ? activeTab === "All" && (
                <div>
                  {cricketMatches
                    ?.filter((item) => item?.inPlay)
                    ?.map((gameEvent) => (
                      <div
                        className={`old-matches-list ${
                          isUpcoming ? "" : "live-match"
                        }`}
                      >
                        <div className="TeamName">
                          <div onClick={() => navigateToGame(gameEvent)}>
                            {gameEvent?.eventName}
                            <span className="d-inline-flex align-items-center float-left mx-2">
                              <div className="blink" />
                            </span>
                          </div>
                        </div>
                        <div className="old-match-details">
                          <div onClick={() => navigateToGame(gameEvent)}>
                            <table
                              width="100%"
                              border="0"
                              cellspacing="0"
                              cellpadding="0"
                            >
                              <tbody>
                                <tr>
                                  <td width="1%">&nbsp;</td>
                                  <td className="GameList">
                                    <table
                                      width="99%"
                                      border="0"
                                      cellspacing="0"
                                      cellpadding="0"
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            className="GameList"
                                            align="center"
                                          >
                                            {moment(
                                              gameEvent.eventTime
                                                ? gameEvent.eventTime
                                                : gameEvent.eventDate
                                            ).format("DD MMM hh:mm A")}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            className="GameList"
                                            align="center"
                                          >
                                            MATCH BETS :{" "}
                                            <span>
                                              {gameEvent?.matchCount
                                                ? gameEvent?.matchCount
                                                : 0}
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            className="GameList"
                                            align="center"
                                          >
                                            Session Bets :{" "}
                                            <span>
                                              {gameEvent?.fancyCount
                                                ? gameEvent?.fancyCount
                                                : 0}
                                            </span>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                  <td width="1%">&nbsp;</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )
            : null}

          {!isUpcoming ? (
            <div>
              {cricketMatches
                ?.filter((item) => !item?.inPlay)
                ?.map((gameEvent) => (
                  <div className={`old-matches-list`}>
                    <div className="TeamName">
                      <div onClick={() => navigateToGame(gameEvent)}>
                        {gameEvent?.eventName}
                      </div>
                    </div>
                    <div className="old-match-details">
                      <div onClick={() => navigateToGame(gameEvent)}>
                        <table
                          width="100%"
                          border="0"
                          cellspacing="0"
                          cellpadding="0"
                        >
                          <tbody>
                            <tr>
                              <td width="1%">&nbsp;</td>
                              <td className="GameList">
                                <table
                                  width="99%"
                                  border="0"
                                  cellspacing="0"
                                  cellpadding="0"
                                >
                                  <tbody>
                                    <tr>
                                      <td className="GameList" align="center">
                                        {moment(
                                          gameEvent.eventTime
                                            ? gameEvent.eventTime
                                            : gameEvent.eventDate
                                        ).format("DD MMM hh:mm A")}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="GameList" align="center">
                                        MATCH BETS :{" "}
                                        <span>
                                          {gameEvent?.matchCount
                                            ? gameEvent?.matchCount
                                            : 0}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="GameList" align="center">
                                        Session Bets :{" "}
                                        <span>
                                          {gameEvent?.fancyCount
                                            ? gameEvent?.fancyCount
                                            : 0}
                                        </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td width="1%">&nbsp;</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ) : null}

          {activeTab === "Cricket" && <div />}
          {activeTab === "Football" && <div />}
          {activeTab === "Tennis" && <div />}
        </div>
      </main>
    </div>
  );
};

export default Matches;
