import JSEncrypt from "jsencrypt";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { loginAPI } from "../service/AuthService";
import { PUBLIC_KEY } from "../utils/constants";
import { Button, InputAdornment } from "@material-ui/core";
import { TextField } from "@mui/material";
import Footer from "./components/Footer";
import { Stack } from "react-bootstrap";
import { generateHash } from "../service";

function LoginPage() {
  const dispatch = useDispatch();
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const login = async (e) => {
    let encryptedPassword = new JSEncrypt();
    encryptedPassword.setPublicKey(PUBLIC_KEY);
    encryptedPassword = encryptedPassword.encrypt(password);
    setLoading(true);
    let fingerprint = await generateHash();
    localStorage.setItem("fingerprint", fingerprint);
    var { data, code, message, response, error } = await loginAPI(
      "C" + userName,
      encryptedPassword
    );

    if (code == 200) {
      setLoading(false);
      dispatch({
        type: "accountData/setUserData",
        payload: response,
      });
      dispatch({
        type: "accountData/setLoginPopSate",
        payload: false,
      });
      dispatch({
        type: "accountData/setChangePopSate",
        payload: response.resetRequired,
      });
      navigate("/tc");
    } else if (code == 500) {
      toast.error(response);
    } else if (code == 403) {
      toast.error(error?.description + " contact admin");
    } else if (code == 401) {
      toast.error(response);
    } else {
      toast.error("Something went wrong or Account is not active");
    }
    setLoading(false);
  };
  return (
    <div className="login-page">
      {loading ? (
        <>
          <div className="loader">
            <img
              src={require("../assets/logo2.png")}
              alt="Loader"
              class="flip-vertical-right"
            />
          </div>
        </>
      ) : null}
      <div className="d-flex align-items-center" style={{ height: "100vh" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <center>
                <p
                  style={{
                    fontSize: "2rem",
                    fontFamily: "Roboto ,Helvetica, Arial,sans-serif",
                    color: "#e91e63",
                    lineHeight: "1.167",
                    fontWeight: 700,
                    letterSpacing: "-0.000162em",
                  }}
                >
                  <b>99betExch</b>
                </p>
              </center>
            </div>
            <div className="m-auto position-relative col-lg-4 col-md-6 col-sm-7 col-12">
              <form>
                <TextField
                  type="text"
                  fullWidth
                  label="Client Code *"
                  id="outlined-basic"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">C</InputAdornment>
                    ),
                  }}
                  style={{ margin: "10px 0px" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={userName}
                  onChange={(e) =>
                    setUsername(e.target.value?.replace("C", ""))
                  }
                />
                <TextField
                  style={{ margin: "10px 0px" }}
                  type="password"
                  fullWidth
                  id="outlined-required"
                  onChange={(e) => setPassword(e.target.value)}
                  label="Password *"
                  variant="outlined"
                />
                <a>
                  <Stack spacing={2} direction="row">
                    <Button
                      onClick={login}
                      variant="contained"
                      fullWidth
                      className="login-button text-white"
                      type="button"
                    >
                      Login
                    </Button>
                  </Stack>
                </a>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default LoginPage;
