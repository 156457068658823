import React, { useEffect, useState } from "react";
import "./casino.css";
import { getAnnouncementAPI } from "../../service/AuthService";
import { Link } from "react-router-dom";
export default function Casino() {
  const [announcement, setAnnouncement] = useState(null);
  const getAnnouncement = async () => {
    const { response } = await getAnnouncementAPI();
    setAnnouncement(response);
  };
  useEffect(() => {
    getAnnouncement();
  }, []);
  return (
    <main className="snipcss-WwCxg">
      
      <table
        width="100%"
        border={0}
        cellSpacing={0}
        cellPadding={0}
        id="style-1YkPW"
        className="style-1YkPW"
      >
        <tbody>
          <tr>
            <td>
              <table width="100%" border={0} cellSpacing={0} cellPadding={0}>
                <tbody>
                  <tr>
                    <td align="center">&nbsp;</td>
                  </tr>
                  <tr>
                    <td align="center">
                      <a
                        routerlink="/casino/live-teen20"
                        href="/casino/live-teen20"
                      >
                        <img
                          src={require("../../assets/teen20.jpg")}
                          alt=""
                          width={112}
                          height={112}
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td height={25} align="center" className="GameList">
                      <a
                        routerlink="#"
                        href="#"
                        id="style-VO51K"
                        className="style-VO51K"
                      >
                        Teen Patti
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td>
              <table width="100%" border={0} cellSpacing={0} cellPadding={0}>
                <tbody>
                  <tr>
                    <td align="center">&nbsp;</td>
                  </tr>
                  <tr>
                    <td align="center">
                      <a href="/casino/ander-bahar">
                        <img
                          src={require("../../assets/ab20.jpg")}
                          alt=""
                          width={112}
                          height={112}
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td height={25} align="center" className="GameList">
                      <a
                        href="/casino/ander-bahar"
                        id="style-fMFYL"
                        className="style-fMFYL"
                      >
                        Ander Bahar
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <table width="100%" border={0} cellSpacing={0} cellPadding={0}>
                <tbody>
                  <tr>
                    <td align="center">&nbsp;</td>
                  </tr>
                  <tr>
                    <td align="center">
                      <a
                        routerlink="/casino/live-teen20"
                        href="/casino/dragon-tiger"
                      >
                        <img
                          src={require("../../assets/dragontiger.png")}
                          alt=""
                          width={112}
                          height={112}
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td height={25} align="center" className="GameList">
                      <a
                        routerlink="#"
                        href="#"
                        id="style-VO51K"
                        className="style-VO51K"
                      >
                        Dragon Tiger
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td>
              <table width="100%" border={0} cellSpacing={0} cellPadding={0}>
                <tbody>
                  <tr>
                    <td align="center">&nbsp;</td>
                  </tr>
                  <tr>
                    <td align="center">
                      <a href="/casino/lucky7">
                        <img
                          src={require("../../assets/duskadum.png")}
                          alt=""
                          width={112}
                          height={112}
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td height={25} align="center" className="GameList">
                      <a
                        href="/casino/lucky7"
                        id="style-fMFYL"
                        className="style-fMFYL"
                      >
                        Lucky 7
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
        </tbody>
      </table>
      <div className="content_bottom">
        <div className="clear" />
      </div>
      <div align="center" className="menu">
        <ul className="navMain">
          <li className="active">
            <Link to="/dashboard" className="style-UbPUG" id="style-UbPUG">
              BACK TO MAIN MENU
            </Link>
          </li>
        </ul>
      </div>
      {/* <app-footer>
        <div className="footer">
          <h4>Copy Right @1Exch.net</h4>
        </div>
      </app-footer> */}
    </main>
  );
}
