import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import {
  getAnnouncementAPI,
  getCurrentBetsAPI,
  getExposureTableAPI,
} from "../../service/AuthService";
import { useDispatch, useSelector } from "react-redux";
import { getUserDataAPI } from "../../service/UserService";
import { useQueries, useQuery } from "react-query";
import { Modal } from "react-bootstrap";
import moment from "moment";

function Header() {
  const location = useLocation();
  const userData = useSelector((state) => state.account.userData);
  const dispatch = useDispatch();
  const [announcement, setAnnouncement] = useState();
  const [exposureTableModal, setExposureTableModal] = useState(false);
  const [state, setState] = useState({
    right: false,
  });
  const myMarketQuery = useQuery("myMarket", () =>
    getCurrentBetsAPI({
      betStatus: "MATCHED",
      betType: "ALL",
      pageNumber: 0,
      pageSize: 100,
      search: "",
      sports: true,
    })
  );
  const navigate = useNavigate();
  const getAnnouncement = async () => {
    try {
      const { response, code } = await getAnnouncementAPI();
      if (code == 200) {
        setAnnouncement(response.announcement);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const getUserData = async () => {
    try {
      var { response, code } = await getUserDataAPI(userData.id);
      if (code === 200) {
        dispatch({
          type: "accountData/setUserProfile",
          payload: response,
        });
      } else {
        dispatch({
          type: "gameData/logOut",
          payload: {},
        });
      }
    } catch (e) {
      dispatch({
        type: "accountData/logOut",
        payload: {},
      });

      localStorage.clear();
      localStorage.removeItem("user");
      localStorage.removeItem("state");
      navigate("/");
      window.location.reload();
    }
  };

  const announcementQuery = useQuery("announcement", getAnnouncement, {
    refetchInterval: 9000,
  });
  const userDataQuery = useQuery("userData", getUserData, {
    refetchInterval: 2200,
  });
  useEffect(() => {
    if (userData) {
      userDataQuery.refetch();
    }
    if (announcement) {
      announcementQuery.refetch();
    }
  }, []);

  if (!userData.token) {
    return (window.location.href = "/");
  }
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      className=""
      id="offcanvasNavbar-expand-navbar"
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div variant="white" className="offcanvas-header">
        <a href="/dashboard" className="logo">
          <img
            style={{
              width: "60px",
              height: "60px",
              objectFit: "cover",
            }}
            src={require("../../assets/logo2.png")}
            alt=""
          />
        </a>
        <button type="button" className="btn-close" aria-label="Close" />
      </div>
      <div className="offcanvas-body">
        <div className="justify-content-end flex-grow-1 pe-3 navbar-nav">
          <Link to="/dashboard" className="active nav-link">
            HOME
          </Link>
          <Link to="/in-play" className="nav-link">
            IN PLAY
          </Link>

          <Link to="/password" className="nav-link">
            PASSWORD
          </Link>
          <Link to="/ledger" className="nav-link">
            LEDGER
          </Link>
          <Link to="/rules" className="nav-link">
            RULES
          </Link>
          <Link to="/casino" className="nav-link">
            CASINO
          </Link>

          <a
            onClick={() => {
              dispatch({
                type: "accountData/logOut",
                payload: {},
              });
              dispatch({
                type: "gameData/logOut",
                payload: {},
              });
              localStorage.clear();

              window.location.href = "/";
            }}
            className="nav-link"
          >
            LOGOUT
          </a>
        </div>
      </div>
    </Box>
  );
  const isMobile = window.innerWidth < 768;

  return (
    <div style={{}}>
      <nav
        style={{
          backgroundColor: "#0336FF",
          display: "flex",
          flexDirection: "row",
        }}
        className="bg-theme-dark p-0 navbar navbar-expand-navbar navbar-dark"
      >
        <div className="container-fluid">
          <div style={{ marginTop: "0px", display: "flex" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
                justifyContent: "center",
              }}
              key="right"
            >
              {/* <Button
                style={{ marginLeft: "-18px" }}
                className="navbar-toggler collapsed"
                variant="outlined"
                onClick={toggleDrawer("right", true)}
              >
                <MenuIcon />
              </Button> */}

              <Drawer
                anchor="right"
                open={state["right"]}
                onClose={toggleDrawer("right", false)}
              >
                {list("right")}
              </Drawer>
            </div>
            <Link to="/dashboard" className="header-logo">
              <img src={require("../../assets/logo2.png")} alt="" />
            </Link>
            {location.pathname.includes("game/cricket") && (
              <div
                onClick={() => {
                  myMarketQuery.refetch();
                  setExposureTableModal(true);
                }}
                className="exposure"
              >
                <center>
                  <p>USED COINS</p>
                  <p>
                    <span
                      className={
                        userData?.liability < 0
                          ? "text-danger"
                          : "text-success"
                      }
                    >
                      {userData?.liability}/-
                    </span>
                  </p>
                </center>
              </div>
            )}
          </div>
          <div
            style={{
              marginTop: "-13px",
            }}
            className="profile-details text-center"
          >
            <p
              className="m-0"
              style={{
                textOverflow: "ellipsis",
              }}
            >
              {userData?.username?.split(" ")[1]} (
              {userData?.username?.split(" ")[0]}){" "}
            </p>
            <p className="m-0">
              chips: {userData?.balance + userData?.liability}
            </p>
          </div>

          <div>
            <a
              onClick={() => {
                dispatch({
                  type: "accountData/logOut",
                  payload: {},
                });
                dispatch({
                  type: "gameData/logOut",
                  payload: {},
                });
                localStorage.clear();

                window.location.href = "/";
              }}
              style={{ cursor: "pointer" }}
            >
              <img
                src="/assets/images/logout.png"
                alt=""
                width={40}
                height={40}
              />
            </a>
          </div>
        </div>
      </nav>

      <Modal
        onHide={() => setExposureTableModal(false)}
        show={exposureTableModal}
      >
        <Modal.Header
          style={{
            backgroundColor: "#f5f5f5",
          }}
          closeButton
        >
          <h4>My Market</h4>
        </Modal.Header>
        <Modal.Body
          style={{
            overflowX: "auto",
            textAlign: "center",
          }}
        >
          <div>UNSETTLED MATCH BETS</div>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Team</th>

                <th>Rate</th>
                <th>Mode</th>
                <th>Amount</th>

                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {myMarketQuery?.data?.response?.unsettledBets?.content
                ?.filter((i) => i.marketName == "BOOKMAKER_ODDS_1")
                ?.map((item) => (
                  <tr>
                    <td>{item?.runner_name}</td>
                    <td>
                      {item?.lay_rate ? item?.lay_rate : item?.back_rate}
                    </td>
                    <td>{item?.lay_rate ? "KHAI" : "LAGAI"}</td>
                    <td>{item?.amount}</td>

                    <td>
                      {moment(item?.created_date + "Z").format(
                        "DD-MM-YYYY HH:mm:ss"
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div>UNSETTLED SESSION BETS</div>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Session</th>

                <th>Rate</th>
                <th>Amount</th>
                <th>Run</th>

                <th>Mode</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {myMarketQuery?.data?.response?.unsettledBets?.content
                ?.filter((i) => i.marketName != "BOOKMAKER_ODDS_1")
                .map((item) => (
                  <tr>
                    <td>{item?.marketName}</td>
                    <td>
                      {item?.lay_rate ? item?.lay_rate : item?.back_rate}
                    </td>
                    <td>{item?.amount}</td>
                    <td>{item?.lay_run ? item?.lay_run : item?.back_run}</td>
                    <td>{item?.lay_rate ? "NO" : "YES"}</td>

                    <td>
                      {moment(item?.created_date + "Z").format(
                        "DD-MM-YYYY HH:mm:ss"
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
      {announcement && (
        <app-header>
          <div className="menu">
            <ul className="navMain">
              <li className="active">
                <a id="style-RmQCI" className="style-RmQCI">
                  <marquee behavior="scorll">{announcement}</marquee>
                </a>
              </li>
            </ul>
          </div>
        </app-header>
      )}
    </div>
  );
}
export default Header;
