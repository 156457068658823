import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function Profile() {
  const userData = useSelector((state) => state.account.userData);
  console.log(userData);
  return (
    <div className="position-relative">
      <Link className="btn btn-primary backbutton w-100" to="/dashboard">
        Back to Main Menu
      </Link>
      <table width="100%" border={0} cellSpacing={0} cellPadding={0}>
        <tbody>
          <tr>
            <td valign="top">
              <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                <tbody>
                  <tr>
                    <td align="left" valign="top">
                      <table
                        width="100%"
                        border={0}
                        cellSpacing={0}
                        cellPadding={0}
                      >
                        <tbody>
                          <tr>
                            <td
                              height={35}
                              align="center"
                              bgcolor="#888399"
                              className="TeamCombo"
                            >
                              <p
                                style={{
                                  color: "#FFF",
                                  fontFamily: "Verdana, Geneva, sans-serif",
                                  fontSize: 13,
                                  fontWeight: "bold",
                                }}
                              >
                                {" "}
                                RATE INFORMATION{" "}
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td align="left" valign="top">
                      <table
                        width="100%"
                        border={0}
                        cellPadding={2}
                        cellSpacing={2}
                      >
                        <tbody>
                          <tr>
                            <td
                              height={35}
                              width="33%"
                              align="left"
                              bgcolor="#FFFFFF"
                              className="FontTextBlue"
                              style={{
                                verticalAlign: "middle",
                                textAlign: "left",
                                paddingRight: 5,
                              }}
                            >
                              Rate Difference :{" "}
                            </td>
                            <td
                              align="center"
                              width="33%"
                              bgcolor="#FFFFFF"
                              className="FontTextBlue"
                              style={{
                                verticalAlign: "middle",
                                textAlign: "center",
                                paddingRight: 5,
                              }}
                            >
                              <select
                                name="rate"
                                className="ng-untouched ng-pristine ng-valid"
                              >
                                <option
                                  value="0.01"
                                  className="ng-star-inserted"
                                >
                                  1
                                </option>
                                <option
                                  value="0.02"
                                  className="ng-star-inserted"
                                >
                                  2
                                </option>
                                <option
                                  value="0.03"
                                  className="ng-star-inserted"
                                >
                                  3
                                </option>
                                <option
                                  value="0.04"
                                  className="ng-star-inserted"
                                >
                                  4
                                </option>
                                <option
                                  value="0.05"
                                  className="ng-star-inserted"
                                >
                                  5
                                </option>
                                {/**/}
                              </select>
                            </td>
                            <td
                              align="center"
                              width="33%"
                              bgcolor="#FFFFFF"
                              className="FontTextBlue"
                              style={{
                                verticalAlign: "middle",
                                textAlign: "center",
                                paddingRight: 5,
                              }}
                            >
                              <div id="menu" align="center" className="menu">
                                <ul className="navMain">
                                  <li className="activeRate">
                                    <a>UPDATE</a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td align="center" valign="bottom" bgcolor="#FFFFFF" />
                  </tr>
                  <tr>
                    <td valign="top" />
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                <tbody>
                  <tr>
                    <td align="left" valign="top">
                      <table
                        width="100%"
                        border={0}
                        cellSpacing={0}
                        cellPadding={0}
                      >
                        <tbody>
                          <tr>
                            <td
                              height={35}
                              align="center"
                              bgcolor="#888399"
                              className="TeamCombo"
                            >
                              <p
                                style={{
                                  color: "#FFF",
                                  fontFamily: "Verdana, Geneva, sans-serif",
                                  fontSize: 13,
                                  fontWeight: "bold",
                                }}
                              >
                                {" "}
                                PERSONAL INFORMATION{" "}
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td align="left" valign="top">
                      <table
                        width="100%"
                        border={0}
                        cellPadding={2}
                        cellSpacing={2}
                      >
                        <tbody>
                          <tr>
                            <td
                              height={35}
                              width="50%"
                              align="left"
                              bgcolor="#FFFFFF"
                              className="FontTextBlue"
                              style={{
                                verticalAlign: "middle",
                                textAlign: "left",
                                paddingRight: 5,
                              }}
                            >
                              Client Code :{" "}
                            </td>
                            <td
                              align="center"
                              width="50%"
                              bgcolor="#FFFFFF"
                              className="FontTextBlue"
                              style={{
                                verticalAlign: "middle",
                                textAlign: "left",
                                paddingRight: 5,
                              }}
                            >
                              {userData?.username?.split(" ")[0]}{" "}
                            </td>
                          </tr>
                          <tr>
                            <td
                              height={35}
                              align="left"
                              bgcolor="#FFFFFF"
                              className="FontTextBlue"
                              style={{
                                verticalAlign: "middle",
                                textAlign: "left",
                                paddingRight: 5,
                              }}
                            >
                              Client Name :{" "}
                            </td>
                            <td
                              align="center"
                              bgcolor="#FFFFFF"
                              className="FontTextBlue"
                              style={{
                                verticalAlign: "middle",
                                textAlign: "left",
                                paddingRight: 5,
                                textTransform: "uppercase",
                              }}
                            >
                              {" "}
                              {userData?.username?.split(" ")[1]}{" "}
                            </td>
                          </tr>
                          <tr>
                            <td
                              height={35}
                              align="left"
                              bgcolor="#FFFFFF"
                              className="FontTextBlue"
                              style={{
                                verticalAlign: "middle",
                                textAlign: "left",
                                paddingRight: 5,
                              }}
                            >
                              Contact No :{" "}
                            </td>
                            <td
                              align="center"
                              bgcolor="#FFFFFF"
                              className="FontTextBlue"
                              style={{
                                verticalAlign: "middle",
                                textAlign: "left",
                                paddingRight: 5,
                              }}
                            >
                              0{" "}
                            </td>
                          </tr>
                          <tr>
                            <td
                              height={35}
                              align="left"
                              bgcolor="#FFFFFF"
                              className="FontTextBlue"
                              style={{
                                verticalAlign: "middle",
                                textAlign: "left",
                                paddingRight: 5,
                              }}
                            >
                              Date Of Joining :{" "}
                            </td>
                            <td
                              align="center"
                              bgcolor="#FFFFFF"
                              className="FontTextBlue"
                              style={{
                                verticalAlign: "middle",
                                textAlign: "left",
                                paddingRight: 5,
                              }}
                            >
                              {moment(userData?.createdAt).format(
                                "MMMM DD, YYYY"
                              )}{" "}
                            </td>
                          </tr>
                          <tr>
                            <td
                              height={38}
                              align="left"
                              bgcolor="#FFFFFF"
                              className="FontTextBlue"
                              style={{
                                verticalAlign: "middle",
                                textAlign: "left",
                                paddingRight: 5,
                              }}
                            >
                              Address :{" "}
                            </td>
                            <td
                              align="center"
                              bgcolor="#FFFFFF"
                              className="FontTextBlue"
                              style={{
                                verticalAlign: "middle",
                                textAlign: "left",
                                paddingRight: 5,
                              }}
                            >
                              {" "}
                              India{" "}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td align="center" valign="bottom" bgcolor="#FFFFFF" />
                  </tr>
                  <tr>
                    <td align="left" valign="top">
                      &nbsp;
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" />
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
                <tbody>
                  <tr>
                    <td align="left" valign="top">
                      <table
                        width="100%"
                        border={0}
                        cellSpacing={0}
                        cellPadding={0}
                      >
                        <tbody>
                          <tr>
                            <td
                              height={35}
                              align="center"
                              bgcolor="#888399"
                              className="TeamCombo"
                            >
                              <p
                                style={{
                                  color: "#FFF",
                                  fontFamily: "Verdana, Geneva, sans-serif",
                                  fontSize: 13,
                                  fontWeight: "bold",
                                }}
                              >
                                {" "}
                                COMPANY INFORMATION{" "}
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td align="left" valign="top">
                      <table
                        width="100%"
                        border={0}
                        cellPadding={2}
                        cellSpacing={2}
                      >
                        <tbody>
                          <tr>
                            <td
                              height={35}
                              width="50%"
                              align="left"
                              bgcolor="#FFFFFF"
                              className="FontTextBlue"
                              style={{
                                verticalAlign: "middle",
                                textAlign: "left",
                                paddingRight: 5,
                              }}
                            >
                              HELP LINE NO :{" "}
                            </td>
                            <td
                              align="center"
                              width="50%"
                              bgcolor="#FFFFFF"
                              className="FontTextBlue"
                              style={{
                                verticalAlign: "middle",
                                textAlign: "left",
                                paddingRight: 5,
                              }}
                            >
                              {" "}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td align="center" valign="bottom" bgcolor="#FFFFFF" />
                  </tr>
                  <tr>
                    <td align="left" valign="top">
                      &nbsp;
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" />
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
