import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import ReactDOM from "react-dom/client";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import "./assets/style.css";

import { useDispatch, useSelector } from "react-redux";
import store, { saveState } from "./redux/store";
import { Toaster } from "react-hot-toast";

import Header from "./pages/components/Header";

import { QueryClient, QueryClientProvider, useQuery } from "react-query";

import "./App.css";
import Matches from "./pages/Matches";
import PlayMatch from "./pages/BetPlay";
import Statement from "./pages/Statement";
import Ledger from "./pages/Ledger";
import ChangePassword from "./pages/ChangePassword";
import Profile from "./pages/Profile";
import Rules from "./pages/Rules";
import CompleteGames from "./pages/CompleteGames";
import CompleteGameInfo from "./pages/CompleteGameInfo";
import TermsAndCondition from "./pages/TermsAndCondition";
import Casino from "./pages/casino/Casino";
import TeenPatti from "./pages/casino/TeenPatti";
import DragonTiger from "./pages/casino/DragonTiger";
import DusKaDum from "./pages/casino/DusKaDum";
import KingQueenJack from "./pages/casino/KingQueenJack";
import SevenUpDown from "./pages/casino/7updown";
import AndarBahar from "./pages/casino/Anderbahar";
import Lucky7 from "./pages/casino/Lucky7";
import CasinoMyBets from "./pages/casino/CasinoMyBet";

export default function App() {
  const userData = useSelector((state) => state.account.userData);
  const [queryClient] = React.useState(() => new QueryClient());
  useEffect(() => {
    store.subscribe(() => {
      saveState(store.getState());
    });
  }, []);
  return (
    <div className="App">
      <Toaster />
      <QueryClientProvider client={queryClient}>
        {userData?.token ? (
          <div
            style={{
              paddingBottom: 50,
              position: "relative",
              overflowY: "hidden",
              minHeight: "100vh",
            }}
          >
            <Header />

            <Routes>
              <Route path="/casino" element={<Casino />} />
              <Route path="/casino/live-teen20" element={<TeenPatti />} />
              <Route path="/casino/dragon-tiger" element={<DragonTiger />} />
              <Route path="/casino/sevenupdown" element={<SevenUpDown />} />
              <Route path="/casino/lucky7" element={<Lucky7 />} />
              <Route path="/casino/casino-bets" element={<CasinoMyBets />} />
              <Route path="/casino/kingqueenjack" element={<KingQueenJack />} />
              <Route path="/casino/ander-bahar" element={<AndarBahar />} />
              <Route path="/" element={<Dashboard active="dashboard" />} />
              <Route
                path="/in-play"
                element={<Matches isUpcoming={false} />}
              />
              <Route
                path="/upcoming"
                element={<Matches isUpcoming={true} />}
              />
              <Route path="/game/cricket/:id" element={<PlayMatch />} />
              <Route path="/complete-games" element={<CompleteGames />} />
              <Route path="/tc" element={<TermsAndCondition />} />
              <Route
                path="/complete-game/:id"
                element={<CompleteGameInfo />}
              />
              <Route
                path="/dashboard"
                element={<Dashboard active="dashboard" />}
              />
              <Route
                path="/statement"
                element={<Statement active="statement" />}
              />
              <Route path="/ledger" element={<Ledger />} />
              <Route path="/password" element={<ChangePassword />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/rules" element={<Rules />} />
            </Routes>
            <footer className="footer">
              Copy Right <a href="">@99betexch</a>
            </footer>
          </div>
        ) : (
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Login />} />
          </Routes>
        )}
      </QueryClientProvider>
    </div>
  );
}
